import React, { Component } from "react";
import { OdooContext } from "../contexts/OdooContext";
import { withStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import { Person, Call, Chat, Lock, ExpandMore, } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import { withRouter, NavLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Divider from "@material-ui/core/Divider";

import "./Navbar.css";

const styles = (theme) => ({
	nested: {
        paddingLeft: theme.spacing(4),
    },
    active: {
        backgroundColor: '#313131 !important',
    },
    activeProject: {
        backgroundColor: '#336CA6 !important',
    },
    padd: {
        marginTop: 20,
    },
    button: {
        margin: theme.spacing(1),
    },
});

class Navbar extends Component {
    
    static contextType = OdooContext;

    constructor(props) {
        super(props);
        this.state = {
            showMenu: null,
        }
    }

    logout = () => {
        localStorage.removeItem('AccessToken');
        localStorage.removeItem('Username');
        localStorage.removeItem('UserLanguage');
        this.setState({showMenu: null});
        this.context.setLoginStatus(false);
        this.props.history.push("/login");
    }

    closeMenu = () => {
        this.setState({showMenu: null})
    }

    openMenu = (event) => {
        this.setState({showMenu: event.currentTarget})
    }

    changeLanguage = (language) => {
        localStorage.setItem('UserLanguage', language);
        this.setState({showMenu: null}, () => {
            this.context.changeLanguage(language);
            // window.location.reload();
        });
    }

    render() {
        const { classes } = this.props;
        const userid = localStorage.getItem('userid')

        return (
            <div className='main'>
                <div className="topNav">
                    <div className="topnavItem">
                        <NavLink to='/home' style={{textDecoration: 'none'}}>
                            <Tooltip title="Home">
                                <IconButton aria-label="home">
                                    <div>
                                    <img
                                        src={require("../assets/logo.png")}
                                        className="logo-image"
                                    />
                                    </div>
                                </IconButton>
                            </Tooltip>
                        </NavLink>
                        {!!this.context.loggedIn && (
                            <div style={{display: 'flex', flexDirection: 'row', marginTop: 5, alignItems: 'center', justifyContent: 'center'}}>
                                <Button
                                    color="primary"
                                    className={classes.button}
                                    endIcon={<ExpandMore>Hello {this.context.username} - </ExpandMore>}
                                    onClick={this.openMenu}
                                >
                                    {this.context.username} - {userid}
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={this.state.showMenu}
                                    keepMounted
                                    open={Boolean(this.state.showMenu)}
                                    onClose={this.closeMenu}
                                >
                                    <MenuItem disabled={true} key='selectLanguage'>Select Language</MenuItem>
                                    <MenuItem onClick={() => this.changeLanguage('en_US')} style={{marginLeft: 10}} selected={this.context.language === 'en_US'} key='en_US'>English</MenuItem>
                                    <MenuItem onClick={() => this.changeLanguage('vi_Vn')} style={{marginLeft: 10}} selected={this.context.language === 'vi_Vn'} key='vi_Vn'>Tiếng Việt</MenuItem>
                                    <Divider />
                                    <MenuItem onClick={this.logout} key='logout'>Logout</MenuItem>
                                </Menu>
                            </div>
                        )}
                    </div>
                </div>
                
                {this.context.loggedIn ? (
                    <div className='leftNav'>
                        <div>
                            <NavLink to='/home' style={{textDecoration: 'none'}}>
                                <Tooltip title="Leads">
                                    <IconButton aria-label="leads">
                                        <Call style={{color: '#fff'}} />
                                    </IconButton>
                                </Tooltip>
                            </NavLink>
                            <Tooltip title="Leaves">
                                <IconButton aria-label="leaves" disabled>
                                    <Person style={{color: '#fff'}} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Chat">
                                <IconButton aria-label="chat" disabled>
                                    <Chat style={{color: '#fff'}} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                ) : (
                    <div className='leftNavNoLogin'></div>
                )}

                <div className={'container'}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default withRouter(withStyles(styles)(Navbar));
