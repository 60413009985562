import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {CALL_ACTIVE} from '../../actionTypes'
import Chip from "@material-ui/core/Chip";
import { useStopwatch } from 'react-timer-hook';
import { Context } from "../phone";

const Timer = styled(({Sip}) => {
  const { isInCall } = useContext(Context);
  const [date, setDate] = useState();
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart:true });

  const format = number => (number < 10 ? `0${number}` : number);

  const label =`${format(minutes)}:${format(seconds)}`;

  return  <Chip label={label} />;
  // return  <Chip label={label} />;
})``;

Timer.displayName = "Timer";

Timer.propTypes = {
  onLoad: PropTypes.func
};

export default Timer;
