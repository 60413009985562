/* eslint-disable no-unused-expressions */
import React,{useState, useEffect,useContext} from "react";
import ReactDOM from "react-dom";
import Error from "./components/error";
import Dialers from "./components/dialer";
import ReactModal from 'react-modal-resizable-draggable';
import { sipPropType, callPropType} from '@evercall/react-sip';
import './style.css'
import {CALL_ACTIVE,CALL_STARTING,CALL_IDLE,CALL_INCOMING,CALL_OUTGOING, 
  CALL_STOPING, SIP_DISCONNECTED,SIP_CONNECTING, SIP_CONNECTED, SIP_REGISTERED} from './actionTypes'
import {OdooContext} from '../../contexts/OdooContext'
import {motion} from 'framer-motion'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {Badge} from 'rsuite';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IncomingTone from '../../assets/tone.mp3'
import {Howl, Howler} from 'howler';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const statusObj=[
  {
    name:'Disconnected',
    color:'error'
  },
  {
    name:'Connected',
    color:'success'
  },
  {
    name:'Registered',
    color:'success'
  },
  {
    name:'Connecting',
    color:'warning'
  },
]

function Dailer(props,context){
    const Sip = context;
    const {showDialer,setShowDialer,incomingCaller,setIncomingCaller,leadData,setIncomingLeadId,token, incomingLeadId} = useContext(OdooContext);
    const [showDialog,setShowDialog] = useState(false)
    const [status,setStatus] = useState('')
    const [isKeypadOpened, setKeypadOpened] = useState(true);
    const [playIncomingTone,setPlayIncomingTone] = useState(false)
    const [incomingAudio,setIncomingAudio] = useState( new Audio(IncomingTone))
    const HowlerSound = new Howl({src:[require('../../assets/tone.mp3')]})
    // const src =
    // "https://api2.totalvoice.com.br/w3/phone.php?key=9c6797a261fc22aff9f8cff306433af9&tipo=hidden";


  async function followProtocol(number,type){
    return new Promise((resolve,reject)=>{
      const userid = localStorage.getItem('userid')
      let dataToSend={
        "agent_id":userid,
        "secret_key": "rTvgfFDT45",
        "destination":number,
        "type":type
      }
      let myResponse = null;
      (async()=>{
        myResponse = await fetch('https://api.thevoicemate.com/api/place_call', {
          method: 'POST',
          body: JSON.stringify(dataToSend),
          headers: {
              'Content-Type': 'application/json; ',
          },

      });
      if(myResponse.status === 200){
        let jsonResp = await myResponse.json();
        if(jsonResp.status){
            resolve(jsonResp)
        }
        console.log('====Data Response====: ',myResponse)
      }else{
        reject('err')
      }
      })()
    })
  }



  async function handleDialCall(){   
    try{
      let jsonResp = await followProtocol(incomingCaller,'out')
      console.log("JSON RESP: ",jsonResp)
      if(Sip.call.status === CALL_IDLE){
        Sip.startCall(jsonResp.destination)
      }
  }catch(e){
    
  }
   
  }
  
  
  


  async function handleReceiveCall(){  
    // const isMediaStopped = await stopMedia()
    const isMediaStopped = true
    console.log("Is Media Stopped :",isMediaStopped)
    let jsonResp = await followProtocol(incomingCaller,'in')
    console.log("JSON RESP: ",jsonResp)
    if(jsonResp.status){
      console.log('Receiving Call=======',Sip.call.status)
      if(Sip.call.status === CALL_STARTING && Sip.call.direction === CALL_INCOMING){
        navigator.mediaDevices.getUserMedia({ audio: {echoCancellation:true,sampleSize:8} })
        .then(function(stream) {
          if(Sip.call.direction === CALL_INCOMING){
            console.log("=======Stream======",stream)
            console.log("Stream allll==",stream.getAudioTracks())
            // window.localStream = stream;
            // let audio = new Audio()
            // audio.srcObject = stream;
            Sip.answerCall()     
          }
        })
        .catch((err)=>{
  
        })
        // Sip.answerCall()     
      } 
    }   
    }


  async function stopMedia(){
    return new Promise((resolve,reject)=>{
      window?.localStream?.getAudioTracks()?.forEach((track)=>{
        track.stop()
      });
      navigator.mediaDevices.getUserMedia({audio:true})
      .then((stream)=>{
        HowlerSound.unload()
        Howler.unload()
        stream.getTracks().forEach((track)=>track.stop())
        if(!HowlerSound.playing()){
          resolve(true)
        }
      })
      .catch((err)=>{
          reject(false)
      })
    })
  }  


  function endCall(){
    if(Sip.call.status === CALL_ACTIVE || Sip.call.status === CALL_STARTING){
      // stopMedia()
      Sip.stopCall()
      setShowDialer(false)
    }
  }  

  function getNumber(string){
    try{
      let a = string.split(':')[1].split('@')[0]
      console.log('Incoming Number: ',a)
        return a? a:'Private Number'
    }catch(e){

    }   
  }

  function playRingTone(playback){
    try{
      window.AudioContext = window.AudioContext||window.webkitAudioContext;
      var audioContext = new AudioContext()
      var localAudio = new FileReader(IncomingTone);
      console.log("Local Audio: ",localAudio)
      localAudio.readAsArrayBuffer(localAudio)
      localAudio.onload =(e)=>{
        audioContext.decodeAudioData(e.target.result,(buffer)=>{
          var incomingTone = audioContext.createBufferSource();
          incomingTone.buffer =buffer;
          incomingTone.connect(audioContext.destination)
          console.log("Incoming Tone======>", incomingTone)
          if(playback === 'play'){
            incomingTone.start(0);
          }
          if(playback === 'stop'){
            incomingTone.stop(0)
          }
        })
      }  
    }catch(e){
      console.log("Audio Error: ",e)
    }
  }


  useEffect(()=>{
    console.log("props....: ",Sip)
    if(Sip.call.status === CALL_STARTING  && Sip.call.direction === CALL_INCOMING){
      console.log("Howler====>",HowlerSound)
      setIncomingCaller(getNumber(Sip.call.counterpart))
      setShowDialer(true)
      setPlayIncomingTone(true)
    }
    if(Sip.call.status === CALL_ACTIVE && Sip.call.direction === CALL_INCOMING){
      let phone = getNumber(Sip.call.counterpart) ;//.slice(4,14)
      if(leadData.length>0 && incomingLeadId === ''){
        let existingLead = leadData.filter((item)=>item.phone.substring(item.phone.length - 9) === phone.substring(phone.length - 9))
        console.table(existingLead)
        if(existingLead.length>0){
          setIncomingLeadId(existingLead[0].id)
        }else{
          setShowDialog(true)
        }
      }
    }

    if(Sip.call.status === CALL_ACTIVE && Sip.call.direction === CALL_OUTGOING){
      navigator.mediaDevices.getUserMedia({audio:true})
      .then((stream)=>{
          window.localStream = stream;
          let audio = new Audio()
          audio.srcObject = stream;
      })
    }


    if(Sip.call.status === CALL_IDLE){
      setShowDialer(false)
      setIncomingLeadId('')
      setIncomingCaller('')
      setPlayIncomingTone(false)
    }

    checkSipStatus()
  },[Sip.call])


  // useEffect(()=>{
  //   if(playIncomingTone){
  //     navigator.mediaDevices.getUserMedia({audio:true})
  //     .then((stream)=>{
  //       console.log("=====Hello=====")
  //       incomingAudio.play()
  //     })
  //   }else{
  //     // stopMedia()
  //     incomingAudio.pause()
  //     // incomingAudio.currentTime =0;
  //     // console.log("=====Stopping Media=====")
  //   }
  // },[playIncomingTone])
  





  function handleNewLead(){
      setIncomingLeadId('new')
      setShowDialog(false)
  }

  
  function checkSipStatus(){
    if(status !== 'enabled'){
      if(Sip.sip.status === SIP_DISCONNECTED){
        setStatus('Disconnected')
      }
      if(Sip.sip.status === SIP_CONNECTING){
        setStatus('Connecting')
      }
      if(Sip.sip.status === SIP_CONNECTED){
        setStatus('Connected')
      }
      if(Sip.sip.status === SIP_REGISTERED){
        setStatus('Registered')
        setTimeout(()=>{
          setStatus('enabled')
        },1500)
      }
    }
 
  }


  const boxPosition={
    position:'absolute',
    right:20,
    overflow: 'hidden',
    boxShadow:'0px 0px 18px 14px rgba(242,242,242,1)'
  }

  const animatedBox={
    closed:{
      height:50,
      bottom:'2%',
      ...boxPosition
    },
    open:{
      height:490,
      bottom:'2%',
      ...boxPosition
    },
    incomingCall:{
      height:290,
      bottom:'2%',
      ...boxPosition
    },
  }

  function getStatusColor(status,type){
    let stat = statusObj.filter((item)=>item.name === status)[0]
    if(stat){
      if(type === 'color'){
        return stat.color
      }
      else{
        return stat.name
      }
    }
    
  }


    return(
       <div className="dialer-modal"  >
         {status === 'enabled'&& 
         <>
      <motion.div
         animate={
           (showDialer && Sip.call.status !== CALL_STARTING && isKeypadOpened) ? 'open' : 
           (showDialer && (Sip.call.status === CALL_STARTING)) ?'incomingCall':
           (showDialer && !isKeypadOpened)?'incomingCall':
           'closed'
          
          }
         variants={animatedBox}
         className="phone-box"
         > 
         <div className="call-heading-box" onClick={()=>setShowDialer(!showDialer)}>
           <img src={require('../../assets/phone.svg')}  alt="phone" />
         </div>
  
        <Dialers
        // src={src} 
        contact={incomingCaller} 
        setCaller={setIncomingCaller}
        className='custom-dialer'
        Sip={Sip}
        handleReceiveCall={handleReceiveCall}
        handleDialCall={handleDialCall}
        handleEndCall={endCall}
        showDialer={showDialer}
        isKeypadOpened={isKeypadOpened}
        setKeypadOpened={setKeypadOpened}
        />
        </motion.div>
        <Dialog
        open={showDialog}
        onClose={()=>setShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"New Lead Coming"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          The incoming caller is not present in our lead database. Do you want to create a new lead?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setShowDialog(false)} color="primary">
            Ignore
          </Button>
          <Button onClick={handleNewLead} color="primary">
            Create Lead
          </Button>
        </DialogActions>
      </Dialog>
      </>}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={status !== '' && status !== 'enabled'}
        autoHideDuration={16000}
        // message={getStatusColor(status,'text')}
        >
          <Alert severity={getStatusColor(status,'color')}>{`SIP ${getStatusColor(status,'text')}`}</Alert>
        </Snackbar>
       </div>
   

    )
}

Dailer.contextTypes = {
	sip: sipPropType,
	call: callPropType,
	
	registerSip: PropTypes.func,
	unregisterSip: PropTypes.func,
	
	answerCall: PropTypes.func,
	startCall: PropTypes.func,
	stopCall: PropTypes.func,
  }



export default Dailer;