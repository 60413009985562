import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import { OdooContext } from "../../contexts/OdooContext";
import './Signin.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import bgimage from  '../../assets/callcenter2.jpg';
const styles = (theme) => ({

	root: {
		height: '100vh',
	  },
	  image: {
		backgroundImage: `url(${bgimage})`,//require('../../assets/callcenter.png'),
		// 'url(https://source.unsplash.com/random)',
		backgroundRepeat: 'no-repeat',
		backgroundColor:
		  theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	  },
	  paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	  },
	  avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	  },
	  form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	  },
	  submit: {
		margin: theme.spacing(3, 0, 2),
	  },
	



    buttonWidth: {
        width: '200px',
	},
	// paper: {
	// 	paddingTop: 50,
	// 	display: "flex",
	// 	flexDirection: "column",
	// 	alignItems: "center",
	// },
	// avatar: {
	// 	margin: theme.spacing(1),
	// 	backgroundColor: theme.palette.secondary.main,
	// },
	// form: {
	// 	width: "100%", // Fix IE 11 issue.
	// 	minWidth: '200px',
	// 	marginTop: theme.spacing(10),
	// 	flexDirection: 'column',
	// 	alignItems: 'center',
	// 	justifyContent: 'center',
	// 	display: 'flex',
	// },
	// submit: {
	// 	margin: theme.spacing(3, 0, 2),
    // },
})
function Copyright() {
	return (
	  <Typography variant="body2" color="textSecondary" align="center">
		{'Copyright © '}
		<Link color="inherit" href="https://thevoicemate.com/">
		Voicemate Technology Co Ltd. Vietnam
		</Link>{' '}
		{new Date().getFullYear()}
		{'.'}
	  </Typography>
	);
  }
class SignIn extends Component {

	static contextType = OdooContext;

	constructor(props) {
		super(props);

		this.state = {
			isLoggedin: false,
			accessToken: "",
			isLoading: false,
			showError: false,
			errorMessage: '',
			email: '',
			password: '',
		};
	}

	redirect = () => {
		this.context.setUserName(this.state.username);
		this.context.setLoginStatus(true);
		this.props.history.push('home')
	}

	login = async (e) => {
		e.preventDefault()

		this.setState({isLoading: true, showError: false, errorMessage: ''})
		
		let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/login/?user_name=${this.state.email}&password=${this.state.password}`);
		} catch (error) {
			console.log(error)
			this.setState({isLoading: false, showError: true, errorMessage: 'Some error occured while logging you in.'})
			return;
		}
		let jsonResp = null;
		console.log(myResponse);
		if(myResponse.status == 200) {
			this.setState({isLoading: false})
			jsonResp = await myResponse.json();
			console.log(jsonResp);
		} else {
			console.log(myResponse)
			this.setState({isLoading: false, showError: true, errorMessage: 'Some error occured while logging you in.'})
			return;
		}

		if(jsonResp) {
			console.log("Data: ", jsonResp)
			if(jsonResp.status == 'True') {
				localStorage.setItem('AccessToken', jsonResp.token);
				localStorage.setItem('Username', jsonResp.name);
				this.setState({isLoggedin: true, accessToken: jsonResp.token, username: jsonResp.name}, () => {
					this.redirect();
				})
			}
			else
			{
				this.setState({isLoading: false, showError: true, errorMessage: 'Invalid Credentials'})

			}
		}
	}

	setEmail = (e) => {
		this.setState({email: e.target.value})
		localStorage.setItem('userid',e.target.value);
	}

	setPassword = (e) => {
		this.setState({password: e.target.value})
	}

	async componentDidMount() {


		try {
			let accessToken = localStorage.getItem('AccessToken');
			if(accessToken) {
				this.setState({isLoggedin: true, accessToken: accessToken,}, () => {
					this.redirect();
				})
			} else {
				this.context.setUserName('');
				this.context.setLoginStatus(false);
				localStorage.removeItem('AccessToken');
				localStorage.removeItem('Username');
			}
		} catch (e) {
			this.props.setUserAuthenticated(false)
			console.log(e)
		}
	}
	
	render() {
		const { classes } = this.props;
		classes.root+=' signincontainer';
		// const classes = useStyles();	
		return (
			<Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
		  <form className={classes.form} noValidate>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="email"
									label="Username"
									name="email"
									autoComplete="Username"
									autoFocus
									onChange={this.setEmail}
									value={this.state.email}
									
								/>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									name="password"
									label="Password"
									type="password"
									id="password"
									autoComplete="current-password"
									onChange={this.setPassword}
									value={this.state.password}
									
								/>
								{this.state.isLoading ? (
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
										className={classes.submit}
									>
										<CircularProgress color='inherit' size='25px' />
									</Button>
								) : (
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
										onClick={this.login}
										className={classes.submit}
									>
										Sign In
									</Button>
								)}
								{/* <Grid container>
									<Grid item xs>
										<Link href="#" variant="body2">
											Forgot password?
										</Link>
									</Grid>
									<Grid item>
										<Link href="#" variant="body2">
											{"Don't have an account? Sign Up"}
										</Link>
									</Grid>
								</Grid> */}
							
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
		  {this.state.showError ? (
						<div style={{marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'red'}}>
							{this.state.errorMessage}
						</div>
					) : null}
		</div>
      </Grid>
    </Grid>
		)
	}
}

export default withRouter(withStyles(styles)(SignIn));
