import React, { Component } from "react";
import { withRouter, } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { observer, inject } from 'mobx-react';

import { OdooContext } from "../contexts/OdooContext";
import { LeadscoreDetailsMapping, } from './utils/Common';

const styles = (theme) => ({
    sidenavRoot: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        maxWidth: '45ch',
        marginBottom: 80,
    },
    inline: {
        display: 'inline',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
})

function containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i].title === obj.title) {
            return true;
        }
    }

    return false;
}

@inject('GeneralStore')
@observer
class TabLeadscore extends Component {

    static contextType = OdooContext;

    constructor(props) {
        super(props)
        this.state = {
            leadscoreIsLoading: true,
            leadscoreIsLoadingMessage: 'Please wait while we load the data...',
            leadscoreShowMessage: false,
            leadscoreMessage: '',
            selectedLeadId: this.props.selectedLeadId,
        }
    }

    getLeadScoreData = async () => {
        this.setState({leadscoreShowMessage: false, leadscoreMessage: '', leadscoreIsLoading: true, leadscoreIsLoadingMessage: 'Please wait while we load the data...'})

        let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/lead_details?lead_id=${this.state.selectedLeadId}&lang=${this.context.language}`);
		} catch (error) {
			console.log(error)
			this.setState({leadscoreShowMessage: true, leadscoreMessage: 'Some error occured while fetching the leadscore details.', leadscoreIsLoading: false, leadscoreIsLoadingMessage: ''})
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			jsonResp = await myResponse.json();
		} else {
            console.log(myResponse)
            this.setState({leadscoreShowMessage: true, leadscoreMessage: 'Some error occured while fetching the leadscore details.', leadscoreIsLoading: false, leadscoreIsLoadingMessage: '', messageColor: 'red'})
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                let myleadscoreData = jsonResp.res[0]['Lead Socring'][0];
                let leadscoreData = [];
                let result = null;
                let totalElements = 0;
                let myData = {};
                let dataPushed = false;
                for (let key of Object.keys(myleadscoreData)) {
                    if(key != 'type') {
                        if(key == 'result') {
                            result = myleadscoreData[key];
                        } else {
                            dataPushed = false;
                            myData[key] = myleadscoreData[key];
                            totalElements++;
                            if((totalElements % 5 == 0) && (totalElements > 1)) {
                                leadscoreData.push(myData);
                                myData = {};
                                dataPushed = true;
                            }
                        }
                    }
                }
                if(!dataPushed) {
                    leadscoreData.push(myData);
                }
                leadscoreData.push({'result': result});
                
                this.setState({leadscoreShowMessage: false, leadscoreMessage: '', leadscoreIsLoading: false, leadscoreIsLoadingMessage: '', messageColor: 'red', leadscoreData,})
			} else {
                this.setState({leadscoreShowMessage: true, leadscoreMessage: 'Some error occured while fetching the leadscore details.', leadscoreIsLoading: false, leadscoreIsLoadingMessage: '', messageColor: 'red'})
            }
		} else {
            this.setState({leadscoreShowMessage: true, leadscoreMessage: 'Some error occured while fetching the leadscore details.', leadscoreIsLoading: false, leadscoreIsLoadingMessage: '', messageColor: 'red'})
        }
    }

    getToken = async () => {
        let token = await localStorage.getItem('AccessToken');
        if(token && token != 'undefined') {
            console.log(token)
            return token;
        } else {
            localStorage.removeItem('AccessToken');
            this.props.history.push('login');
        }
    }

    componentWillReceiveProps(props) {
        const { leadscoreTabClicked, selectedLeadId, clearMessageStateTabs, } = this.props;
        if (props.clearMessageStateTabs !== clearMessageStateTabs) {
            this.setState({leadscoreIsLoading: false, leadscoreIsLoadingMessage: '', leadscoreShowMessage: false, leadscoreMessage: ''})
        }
        if (props.leadscoreTabClicked !== leadscoreTabClicked) {
            this.getLeadScoreData();
        }
        if(props.selectedLeadId !== selectedLeadId) {
            this.setState({selectedLeadId})
        }
    }

    async componentDidMount() {
        console.log('TabLeadscore mounted!')
        let token = await this.getToken();
        this.setState({token})
        await this.getLeadScoreData();
    }

	render() {
		const { classes } = this.props;

		return (
            <React.Fragment>
                {this.state.leadscoreIsLoading ? (
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', height: '80%', justifyContent: 'center'}}>
                        <CircularProgress color='#256cd6' size='50px' style={{color: '#256cd6'}} />
                        <Typography variant="body2" style={{color: '#256cd6'}}>
                            {this.state.leadscoreIsLoadingMessage}
                        </Typography>
                    </div>
                ) : (
                    <React.Fragment>
                        {this.state.leadscoreShowMessage ? (
                            <div style={{flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', height: '80%', justifyContent: 'center', marginTop: 20}}>
                                <div style={{marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', color: this.state.messageColor ? this.state.messageColor : '#256cd6'}}>
                                    <span style={{fontSize: '20px'}}>{this.state.leadscoreMessage}</span>
                                </div>
                            </div>
                        ) : (
                        <div style={{display: 'flex', flexDirection: 'row', width: '100%', paddingRight: 20,}}>
                            {this.state.leadscoreData.map((data, index) => {
                            return (
                                <React.Fragment>
                                    <div style={{display: 'flex', flexDirection: 'column', margin: 20}}>
                                        {Object.entries(data).map(([key,value],i) => {
                                            return(
                                                <React.Fragment>
                                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 10}} key={`leadscore_${i}`}>
                                                        <Typography variant="caption" className={classes.title} style={{paddingRight: 20, alignSelf: 'center', fontWeight: 'bold'}}>
                                                            {LeadscoreDetailsMapping[key]}
                                                        </Typography>
                                                        <Typography variant="body2" className={classes.title} style={{paddingLeft: 20, alignSelf: 'flex-end'}} align='right'>
                                                            {value}
                                                        </Typography>
                                                    </div>
                                                    <Divider />
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                    {((this.props.GeneralStore.personalData.length - 1) != index) &&
                                        <Divider orientation="vertical" flexItem />
                                    }
                                </React.Fragment>
                            )})}
                        </div>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(withStyles(styles)(TabLeadscore));
