import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {CALL_ACTIVE, CALL_STARTING} from '../../actionTypes'
import Phone from "../phone";
import Input from "../input";
import Status from "../status";
import Timer from "../timer";
import Keypad from "../keypad";
import Controls from "../controls";

const Dialer = styled(({ 
  className, 
  src, 
  contact, 
  startCalling, 
  handleReceiveCall,
  Sip, 
  handleDialCall, 
  setCaller, 
  handleEndCall,
  showDialer, 
  isKeypadOpened,
  setKeypadOpened
}) => {
  const handleToggleKeypad = () => {
    setKeypadOpened(isKeypadOpened => !isKeypadOpened);
  };
  return (
    <Phone
      className={className}
      src={src}
      number={contact}
      startCalling={startCalling}
    >
      
      <Input
        value={contact}
        placeholder="Mobile Number"
        onChange={setCaller}
        showDialer={showDialer}
      />
      {/* <Status /> */}
      {(Sip.call.status === CALL_ACTIVE)&&<Timer Sip={Sip} />}
      {isKeypadOpened &&(Sip.call.status !== CALL_STARTING)&& <Keypad value={contact} onChange={setCaller} />}
      <Controls 
      number={contact} 
      onToggleKeypad={handleToggleKeypad} 
      handleReceiveCall={handleReceiveCall}
      handleDialCall={handleDialCall}
      handleEndCall={handleEndCall}
      Sip={Sip}
      />
    </Phone>
  );
})`
  max-width: 257px;
`;

Dialer.displayName = "Dialer";

Dialer.propTypes = {
  src: PropTypes.string,
  contact: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string
  }),
  startCalling: PropTypes.bool // TODO
};

export default Dialer;
