import React, { Component } from "react";
import { withRouter, } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { observer, inject } from 'mobx-react';
import StarRateIcon from '@material-ui/icons/StarRate';

import { OdooContext } from "../contexts/OdooContext";
import CustomTableNew from './utils/CustomTableNew';
import { PersonalDetailsMapping, PersonalDetailsMapping_Vt, PersonalDetailsDropdowns, PartnerCompanyExperienceMapping, PartnerCompanyExperienceMapping_Vt, PartnerCustomerFamilyMapping, PartnerCustomerFamilyMapping_Vt, PartnerCustomerEducationMapping, PartnerCustomerEducationMapping_Vt, availableValues, PersonalDetailsKeysNotToCheck, PartnerCompanyExperienceKeysNotToCheck, PartnerCompanyExperienceDropdowns, PartnerCustomerFamilyDropdowns, PartnerCustomerFamilyKeysNotToCheck, PartnerCustomerEducationDropdowns, PartnerCustomerEducationKeysNotToCheck, DateValues } from './utils/Common';
import Autocomplete from '@material-ui/lab/Autocomplete';

const styles = (theme) => ({
    sidenavRoot: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        maxWidth: '45ch',
        marginBottom: 80,
    },
    inline: {
        display: 'inline',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 250,
    },
})

function containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i].title === obj.title) {
            return true;
        }
    }

    return false;
}

// function _calculateAge(birthDate) {
//     var today = new Date();
//     var age = today.getFullYear() - birthDate.getFullYear();
//     var m = today.getMonth() - birthDate.getMonth();
//     if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
//         age--;
//     }
//     return age;
// }

function _calculateAge(birthday) {
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

@inject('GeneralStore')
@observer
class TabPersonal extends Component {

    static contextType = OdooContext;

    constructor(props) {
        super(props)
        this.state = {
            allCities: [],
            selectedCity: null,
            allStates: [],
            allCountries: [],
            personalIsLoading: true,
            personalIsLoadingMessage: 'Please wait while we load the data...',
            personalShowMessage: false,
            personalMessage: '',
            personalData: [],
            personalDataKeys: [],
            partnerCompanyExperienceData: [],
            partnerCustomerFamilyData: [],
            partnerCustomerEducationData: [],
            selectedLeadId: this.props.selectedLeadId,
        }
    }

    changeText = (key, myValue) => {
        console.log("KAILASH", key, myValue)
        if(key == 'monthly_income') {
            if(myValue <= 3000000) {
                this.props.GeneralStore.onChangeText('income', '1');
            } else if ((myValue > 3000000) && (myValue <= 5000000)) {
                this.props.GeneralStore.onChangeText('income', '2');
            } else if ((myValue > 5000000) && (myValue <= 8000000)) {
                this.props.GeneralStore.onChangeText('income', '3');
            } else if ((myValue > 8000000) && (myValue <= 10000000)) {
                this.props.GeneralStore.onChangeText('income', '4');
            } else if ((myValue > 10000000) && (myValue <= 15000000)) {
                this.props.GeneralStore.onChangeText('income', '5');
            } else if (myValue > 15000000) {
                this.props.GeneralStore.onChangeText('income', '6');
            }
        } else if (key == 'state') {
            let allStates = this.state.allStates;
            console.log('KAILASH', allStates)
            for(let i = 0; i < allStates.length; i++) {
                if(allStates[i].id == myValue) {
                    this.props.GeneralStore.onChangeText('country', allStates[i].country_id)
                }
            }
        } else if (key == 'birth_date') {
            let age = _calculateAge(new Date(myValue));
            this.props.GeneralStore.onChangeText('age', age)
        }
        this.props.GeneralStore.onChangeText(key, myValue)
    }

    preparePersonalData = async (myPersonalData) => {
        let personalData = [];
        let partnerCompanyExperienceData = [];
        let partnerCustomerFamilyData = [];
        let partnerCustomerEducationData = [];
        
        let totalElements = 0;
        let totalElementsCompanyExperience = 0;
        let totalElementsCustomerFamily = 0;
        let totalElementsCustomerEducation = 0;
        let myData = {};
        let myDataCompanyExperience = {};
        let myDataCustomerFamily = {};
        let myDataCustomerEducation = {};

        for(let i = 0; i < myPersonalData.length; i++) {
            let dataPushed = false;
            let dataPushedCompanyExperience = false;
            let dataPushedCustomerFamily = false;
            let dataPushedCustomerEducation = false;
            for (let key of Object.keys(myPersonalData[i])) {
                if((key == 'partner_company_experience') || (key == 'partner_comapny_experience')) {
                    let myPersonalDataCompanyExperience = myPersonalData[0][key];
                    for (let key1 of Object.keys(myPersonalDataCompanyExperience)) {
                        let keyName = `personal_partnerCompanyExperience_${key1}`;
                        dataPushedCompanyExperience = false;
                        if(this.props.GeneralStore.toReloadPersonal) {
                            this.props.GeneralStore.onChangeText(keyName, myPersonalDataCompanyExperience[key1]);
                        }
                        myDataCompanyExperience[key1] = myPersonalDataCompanyExperience[key1];
                        totalElementsCompanyExperience++;
                        if((totalElementsCompanyExperience % 3 == 0) && (totalElementsCompanyExperience > 1)) {
                            partnerCompanyExperienceData.push(myDataCompanyExperience);
                            myDataCompanyExperience = {};
                            dataPushedCompanyExperience = true;
                        }
                    }
                } else if (key == 'partner_customer_family') {
                    let myPersonalDataCustomerFamily = myPersonalData[0][key];
                    for (let key1 of Object.keys(myPersonalDataCustomerFamily)) {
                        let keyName = `personal_partnerCustomerFamily_${key1}`;
                        dataPushedCustomerFamily = false;
                        if(this.props.GeneralStore.toReloadPersonal) {
                            this.props.GeneralStore.onChangeText(keyName, myPersonalDataCustomerFamily[key1]);
                        }
                        myDataCustomerFamily[key1] = myPersonalDataCustomerFamily[key1];
                        totalElementsCustomerFamily++;
                        if((totalElementsCustomerFamily % 6 == 0) && (totalElementsCustomerFamily > 1)) {
                            partnerCustomerFamilyData.push(myDataCustomerFamily);
                            myDataCustomerFamily = {};
                            dataPushedCustomerFamily = true;
                        }
                    }
                } else if (key == 'partner_customer_education') {
                    let myPersonalDataCustomerEducation = myPersonalData[0][key];
                    for (let key1 of Object.keys(myPersonalDataCustomerEducation)) {
                        let keyName = `personal_partnerCustomerEducation_${key1}`;
                        dataPushedCustomerEducation = false;
                        if(this.props.GeneralStore.toReloadPersonal) {
                            this.props.GeneralStore.onChangeText(keyName, myPersonalDataCustomerEducation[key1]);
                        }
                        myDataCustomerEducation[key1] = myPersonalDataCustomerEducation[key1];
                        totalElementsCustomerEducation++;
                        if((totalElementsCustomerEducation % 1 == 0) && (totalElementsCustomerEducation > 1)) {
                            partnerCustomerEducationData.push(myDataCustomerEducation);
                            myDataCustomerEducation = {};
                            dataPushedCustomerEducation = true;
                        }
                    }
                } else {
                    dataPushed = false;
                    if(this.props.GeneralStore.toReloadPersonal) {
                        this.props.GeneralStore.onChangeText(key, myPersonalData[i][key]);
                    }
                    myData[key] = myPersonalData[i][key];
                    totalElements++;
                    if((totalElements % 12 == 0) && (totalElements > 1)) {
                        personalData.push(myData);
                        myData = {};
                        dataPushed = true;
                    }
                }
            }
            if(!dataPushed) {
                personalData.push(myData);
            }
            if(!dataPushedCompanyExperience) {
                partnerCompanyExperienceData.push(myDataCompanyExperience);
            }
            if(!dataPushedCustomerFamily) {
                partnerCustomerFamilyData.push(myDataCustomerFamily);
            }
            if(!dataPushedCustomerEducation) {
                partnerCustomerEducationData.push(myDataCustomerEducation);
            }
        }

        if(this.props.GeneralStore.toReloadPersonal) {
            this.props.GeneralStore.setPersonalData(personalData);
            this.props.GeneralStore.setPersonalCompanyExperienceData(partnerCompanyExperienceData);
            this.props.GeneralStore.setPersonalCustomerFamilyData(partnerCustomerFamilyData);
            this.props.GeneralStore.setPersonalCustomerEducationData(partnerCustomerEducationData);
            this.props.GeneralStore.setPersonalReload(false);
        }
        this.setState({personalShowMessage: false, personalMessage: '', personalIsLoading: false, personalIsLoadingMessage: ''})
    }

    getPersonalData = async () => {
        this.setState({personalShowMessage: false, personalMessage: '', personalIsLoading: true, personalIsLoadingMessage: 'Please wait while we load the data...'})

        let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/lead_details?lead_id=${this.state.selectedLeadId}&lang=${this.context.language}`);
		} catch (error) {
			console.log(error)
			this.setState({personalShowMessage: true, personalMessage: 'Some error occured while fetching the personal details.', personalIsLoading: false, personalIsLoadingMessage: ''})
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			jsonResp = await myResponse.json();
		} else {
            console.log(myResponse)
            this.setState({personalShowMessage: true, personalMessage: 'Some error occured while fetching the personal details.', personalIsLoading: false, personalIsLoadingMessage: '', messageColor: 'red'})
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                let mypersonalData = jsonResp.res[0]['Personal Details'];
                await this.preparePersonalData(mypersonalData)
			} else {
                this.setState({personalShowMessage: true, personalMessage: 'Some error occured while fetching the personal details.', personalIsLoading: false, personalIsLoadingMessage: '', messageColor: 'red'})
            }
		} else {
            this.setState({personalShowMessage: true, personalMessage: 'Some error occured while fetching the personal details.', personalIsLoading: false, personalIsLoadingMessage: '', messageColor: 'red'})
        }
    }

    getToken = async () => {
        let token = await localStorage.getItem('AccessToken');
        if(token && token != 'undefined') {
            console.log(token)
            return token;
        } else {
            localStorage.removeItem('AccessToken');
            this.props.history.push('login');
        }
    }

    getCountryList = async (token) => {
        let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/country_list/?token=${token}`);
		} catch (error) {
			console.log(error)
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			this.setState({isLoading: false})
			jsonResp = await myResponse.json();
		} else {
			console.log(myResponse)
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                let allCountries = jsonResp.res;
                this.setState({allCountries})
			} else {
                console.log(jsonResp)
            }
		} else {
            console.log(jsonResp)
        }
    }

    getStateList = async (token) => {
        let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/state_list/?token=${token}`);
		} catch (error) {
			console.log(error)
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			this.setState({isLoading: false})
			jsonResp = await myResponse.json();
		} else {
			console.log(myResponse)
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                let allStates = jsonResp.res;
                this.setState({allStates})
			} else {
                console.log(jsonResp)
            }
		} else {
            console.log(jsonResp)
        }
    }

    getCityList = async (token) => {
        let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/city_list/?token=${token}`);
		} catch (error) {
			console.log(error)
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			this.setState({isLoading: false})
			jsonResp = await myResponse.json();
		} else {
			console.log(myResponse)
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                let allCities = jsonResp.res;
                this.setState({allCities})
			} else {
                console.log(jsonResp)
            }
		} else {
            console.log(jsonResp)
        }
    }

    componentWillReceiveProps(props) {
        const { personalTabClicked, selectedLeadId, clearMessageStateTabs, } = this.props;
        if (props.clearMessageStateTabs !== clearMessageStateTabs) {
            this.setState({personalIsLoading: false, personalIsLoadingMessage: '', personalShowMessage: false, personalMessage: ''})
        }
        if (props.personalTabClicked !== personalTabClicked) {
            this.getPersonalData();
        }
        if(props.selectedLeadId !== selectedLeadId) {
            this.setState({selectedLeadId})
        }
    }

    async componentDidMount() {
        console.log('Personal mounted!')
        let token = await this.getToken();
        this.setState({token})
        await this.getCityList(token);
        await this.getStateList(token);
        await this.getCountryList(token);
        if(this.props.GeneralStore.toReloadPersonal) {
            // this.props.GeneralStore.setGeneralData([])
            await this.getPersonalData();
        } else {
            await this.getPersonalData();
            // this.setState({personalIsLoading: false})
        }
    }

    getAutocompleteValue = (key) => {
        let allItems = null;
        if(key == 'city') {
            allItems = this.state.allCities;
        } else if (key == 'state') {
            allItems = this.state.allStates
        } else if (key == 'country') {
            allItems = this.state.allCountries;
        } else {
            allItems = availableValues[key];
        }
        
        let selectedValue = {};
        for(let i = 0; i < allItems.length; i++) {
            if(key == 'city' || key == 'state' || key == 'country') {
                if(allItems[i].id == this.props.GeneralStore[key]) {
                    selectedValue = allItems[i];
                }
            } else {
                if(allItems[i].value == this.props.GeneralStore[key]) {
                    selectedValue = allItems[i];
                }
            }
        }
        
        return selectedValue;
    }

	render() {
		const { classes } = this.props;

		return (
            <React.Fragment>
                {this.state.personalIsLoading ? (
                    <div style={{flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', height: '80%', justifyContent: 'center'}}>
                        <CircularProgress color='#256cd6' size='50px' style={{color: '#256cd6'}} />
                        <Typography variant="body2" style={{color: '#256cd6'}}>
                            {this.state.personalIsLoadingMessage}
                        </Typography>
                    </div>
                ) : (
                    <React.Fragment>
                        {!!this.state.personalShowMessage &&
                            <div style={{flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', height: '80%', justifyContent: 'center', marginTop: 20}}>
                                <div style={{marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', color: this.state.messageColor ? this.state.messageColor : '#256cd6'}}>
                                    <span style={{fontSize: '20px'}}>{this.state.personalMessage}</span>
                                </div>
                            </div>
                        }
                        <div style={{display: 'flex', flexDirection: 'row', width: '100%', paddingRight: 20,}}>
                            {this.props.GeneralStore.personalData.map((data, index) => {
                            return (
                                <React.Fragment>
                                    <div style={{display: 'flex', flexDirection: 'column', margin: 20}}>
                                        {Object.entries(data).map(([key,value],i) => {
                                            return(
                                            !(PersonalDetailsKeysNotToCheck.includes(key)) && (
                                                <React.Fragment>
                                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 10}} key={`personal_${i}`}>
                                                        <Typography variant="caption" className={classes.title} style={{paddingRight: 20, alignSelf: 'center', fontWeight: 'bold'}}>
                                                            {this.context.language === 'en_US' ? PersonalDetailsMapping[key] : PersonalDetailsMapping_Vt[key]} {!!this.props.GeneralStore.isEditable && (
                                                                Object.entries(this.props.GeneralStore.highlightedFields).map(([key2,value2],i) => {
                                                                    if(key === value2) {
                                                                        return (<StarRateIcon style={{color: 'red', height: 10, width: 10,}} />);
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                })
                                                            )}
                                                        </Typography>
                                                        {this.props.GeneralStore.isEditable ? (
                                                            PersonalDetailsDropdowns.includes(key) ? (
                                                                <FormControl style={{minWidth: 250}}>
                                                                    <Autocomplete
                                                                        labelId={`personalDetails_${key}-select-label`}
                                                                        id={`personalDetails_${key}-select-label`}
                                                                        onChange={(event,value) => {
                                                                            this.changeText(key, 
                                                                                (key == 'city') ? (value ? value.id : null) : 
                                                                                (key == 'state' ? (value ? value.id : null) : 
                                                                                (key == 'country' ? (value ? value.id : null) : 
                                                                                (value ? value.value : null))))}}
                                                                        options={
                                                                            key == 'city' ? this.state.allCities:
                                                                            (key == 'state' ? this.state.allStates:
                                                                            (key == 'country' ? this.state.allCountries:
                                                                            availableValues[key]))
                                                                        }
                                                                        getOptionLabel={(option) => option.name || ''}
                                                                        value={this.getAutocompleteValue(key)}
                                                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                                        autoComplete={false}
                                                                    />
                                                                </FormControl>
                                                            ) : (
                                                                (key == 'birth_date') ? (
                                                                    <FormControl style={{minWidth: 250}}>
                                                                        <TextField
                                                                            id={`outlined-basic-${key}`}
                                                                            label={this.context.language === 'en_US' ? PersonalDetailsMapping[key] : PersonalDetailsMapping_Vt[key]}
                                                                            type="date"
                                                                            className={classes.textField}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            onChange={event => this.changeText(key, event.target.value)}
                                                                            value={this.props.GeneralStore[key]}
                                                                        />
                                                                    </FormControl>
                                                                ) : (
                                                                    <FormControl style={{minWidth: 250}}>
                                                                        <TextField id={`outlined-basic-${key}`} label={this.context.language === 'en_US' ? PersonalDetailsMapping[key] : PersonalDetailsMapping_Vt[key]} variant="outlined" onChange={event => this.changeText(key, event.target.value)} value={this.props.GeneralStore[key]} />
                                                                    </FormControl>
                                                                )
                                                            )
                                                        ) : (
                                                            <Typography variant="body2" className={classes.title} style={{paddingLeft: 20, alignSelf: 'flex-end'}} align='right'>
                                                                {PersonalDetailsDropdowns.includes(key) ? (
                                                                    (key == 'city') ? (
                                                                        this.state.allCities.map((item) => (
                                                                            item.id == value ? item.name : item.name == value ? item.name : null
                                                                        ))
                                                                    ) : (
                                                                        (key == 'state') ? (
                                                                            this.state.allStates.map((item) => (
                                                                                item.id == value ? item.name : item.name == value ? item.name : null
                                                                            ))
                                                                        ) : (
                                                                            (key == 'country') ? (
                                                                                this.state.allCountries.map((item) => (
                                                                                    item.id == value ? item.name : item.name == value ? item.name : null
                                                                                ))
                                                                            ) : 
                                                                            (
                                                                                availableValues[key].map((item) => (
                                                                                    item.value == value ? item.name : null
                                                                                ))
                                                                            )
                                                                        )
                                                                    )
                                                                ) : (
                                                                    value
                                                                )}
                                                            </Typography>
                                                        )}
                                                    </div>
                                                    <Divider />
                                                </React.Fragment>
                                            )
                                        )})}
                                    </div>
                                    {((this.props.GeneralStore.personalData.length - 1) != index) &&
                                        <Divider orientation="vertical" flexItem />
                                    }
                                </React.Fragment>
                            )})}
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', paddingRight: 20, marginTop: 20}}>
                            <Typography component="h1" variant="h5" style={{color: '#256cd6', marginBottom: 10, alignSelf: 'flex-start'}} align='left'>
                                {this.context.language === 'en_US' ? 'Company Experience' : 'Kinh nghiệm làm việc'}
                            </Typography>
                            <div style={{display: 'flex', flexDirection: 'row', width: '100%', paddingRight: 20,}}>
                                {this.props.GeneralStore.personalDataCompanyExperienceData.map((data, index) => {
                                return (
                                    <React.Fragment>
                                        <div style={{display: 'flex', flexDirection: 'column', margin: 20}}>
                                            {Object.entries(data).map(([key,value],i) => {
                                                return(
                                                !(PartnerCompanyExperienceKeysNotToCheck.includes(key)) && (
                                                    <React.Fragment>
                                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 10}} key={`personalPartnerConpanyExperience_${i}`}>
                                                            <Typography variant="caption" className={classes.title} style={{paddingRight: 20, alignSelf: 'center', fontWeight: 'bold'}}>
                                                                {this.context.language === 'en_US' ? PartnerCompanyExperienceMapping[key] : PartnerCompanyExperienceMapping_Vt[key]} {!!this.props.GeneralStore.isEditable && (
                                                                    Object.entries(this.props.GeneralStore.highlightedFields).map(([key2,value2],i) => {
                                                                        if(key === value2) {
                                                                            return (<StarRateIcon style={{color: 'red', height: 10, width: 10,}} />);
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })
                                                                )}
                                                            </Typography>
                                                            {this.props.GeneralStore.isEditable ? (
                                                                PartnerCompanyExperienceDropdowns.includes(key) ? (
                                                                    <FormControl style={{minWidth: 250}}>
                                                                        <Autocomplete
                                                                            labelId={`personalPartnerConpanyExperience_${key}-select-label`}
                                                                            id={`personalPartnerConpanyExperience_${key}-select-label`}
                                                                            onChange={(event,value) => {this.changeText(`personal_partnerCompanyExperience_${key}`, (value ? value.value : null))}}
                                                                            options={
                                                                                availableValues[`personal_partnerCompanyExperience_${key}`]
                                                                            }
                                                                            getOptionLabel={(option) => option.name || ''}
                                                                            value={this.getAutocompleteValue(`personal_partnerCompanyExperience_${key}`)}
                                                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                                            autoComplete={false}
                                                                        />
                                                                    </FormControl>
                                                                ) : (
                                                                    DateValues.includes(`personal_partnerCompanyExperience_${key}`) ? (
                                                                        <FormControl style={{minWidth: 250}}>
                                                                            <TextField
                                                                                id={`personal_partnerCompanyExperience_outlined-basic-${key}`}
                                                                                label={this.context.language === 'en_US' ? PartnerCompanyExperienceMapping[key] : PartnerCompanyExperienceMapping_Vt[key]}
                                                                                type="date"
                                                                                className={classes.textField}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                onChange={event => this.changeText(`personal_partnerCompanyExperience_${key}`, event.target.value)}
                                                                                value={this.props.GeneralStore[`personal_partnerCompanyExperience_${key}`]}
                                                                            />
                                                                        </FormControl>
                                                                    ) : (
                                                                        <FormControl style={{minWidth: 250}}>
                                                                            <TextField id={`outlined-basic-${key}`} label={this.context.language === 'en_US' ? PartnerCompanyExperienceMapping[key] : PartnerCompanyExperienceMapping_Vt[key]} variant="outlined" onChange={event => this.changeText(`personal_partnerCompanyExperience_${key}`, event.target.value)} value={this.props.GeneralStore[`personal_partnerCompanyExperience_${key}`]} />
                                                                        </FormControl>
                                                                    )
                                                                )
                                                            ) : (
                                                                <Typography variant="body2" className={classes.title} style={{paddingLeft: 20, alignSelf: 'flex-end'}} align='right'>
                                                                    {PartnerCompanyExperienceDropdowns.includes(key) ? (
                                                                        availableValues[`personal_partnerCompanyExperience_${key}`].map((item) => (
                                                                            item.value == value ? item.name : null
                                                                        ))
                                                                    ) : (
                                                                        value
                                                                    )}
                                                                </Typography>
                                                            )}
                                                        </div>
                                                        <Divider />
                                                    </React.Fragment>
                                                )
                                            )})}
                                        </div>
                                        {((this.props.GeneralStore.personalDataCompanyExperienceData.length - 1) != index) &&
                                            <Divider orientation="vertical" flexItem />
                                        }
                                    </React.Fragment>
                                )})}
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', paddingRight: 20, marginTop: 20}}>
                            <Typography component="h1" variant="h5" style={{color: '#256cd6', marginBottom: 10, alignSelf: 'flex-start'}} align='left'>
                                {this.context.language === 'en_US' ? 'Customer Family' : 'thông tin gia đình'}
                            </Typography>
                            <div style={{display: 'flex', flexDirection: 'row', width: '100%', paddingRight: 20,}}>
                                {this.props.GeneralStore.personalDataCustomerFamilyData.map((data, index) => {
                                return (
                                    <React.Fragment>
                                        <div style={{display: 'flex', flexDirection: 'column', margin: 20}}>
                                            {Object.entries(data).map(([key,value],i) => {
                                                return(
                                                !(PartnerCustomerFamilyKeysNotToCheck.includes(key)) && (
                                                    <React.Fragment>
                                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 10}} key={`personalPartnerConpanyExperience_${i}`}>
                                                            <Typography variant="caption" className={classes.title} style={{paddingRight: 20, alignSelf: 'center', fontWeight: 'bold'}}>
                                                                {this.context.language === 'en_US' ? PartnerCustomerFamilyMapping[key] : PartnerCustomerFamilyMapping_Vt[key]} {!!this.props.GeneralStore.isEditable && (
                                                                    Object.entries(this.props.GeneralStore.highlightedFields).map(([key2,value2],i) => {
                                                                        if(key === value2) {
                                                                            return (<StarRateIcon style={{color: 'red', height: 10, width: 10,}} />);
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })
                                                                )}
                                                            </Typography>
                                                            {this.props.GeneralStore.isEditable ? (
                                                                PartnerCustomerFamilyDropdowns.includes(key) ? (
                                                                    <FormControl style={{minWidth: 250}}>
                                                                        <Autocomplete
                                                                            labelId={`partnerCustomerFamilyDropdowns${key}-select-label`}
                                                                            id={`partnerCustomerFamilyDropdowns${key}-select-label`}
                                                                            onChange={(event,value) => {this.changeText(`personal_partnerCustomerFamily_${key}`, (value ? value.value : null))}}
                                                                            options={
                                                                                availableValues[`personal_partnerCustomerFamily_${key}`]
                                                                            }
                                                                            getOptionLabel={(option) => option.name || ''}
                                                                            value={this.getAutocompleteValue(`personal_partnerCustomerFamily_${key}`)}
                                                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                                            autoComplete={false}
                                                                        />
                                                                    </FormControl>
                                                                ) : (
                                                                    DateValues.includes(`personal_partnerCustomerFamily_${key}`) ? (
                                                                        <FormControl style={{minWidth: 250}}>
                                                                            <TextField
                                                                                id={`personal_partnerCustomerFamily_outlined-basic-${key}`}
                                                                                label={this.context.language === 'en_US' ? PartnerCustomerFamilyMapping[key] : PartnerCustomerFamilyMapping_Vt[key]}
                                                                                type="date"
                                                                                className={classes.textField}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                onChange={event => this.changeText(`personal_partnerCustomerFamily_${key}`, event.target.value)}
                                                                                value={this.props.GeneralStore[`personal_partnerCustomerFamily_${key}`]}
                                                                            />
                                                                        </FormControl>
                                                                    ) : (
                                                                        <FormControl style={{minWidth: 250}}>
                                                                            <TextField id={`outlined-basic-${key}`} label={this.context.language === 'en_US' ? PartnerCustomerFamilyMapping[key] : PartnerCustomerFamilyMapping_Vt[key]} variant="outlined" onChange={event => this.changeText(`personal_partnerCustomerFamily_${key}`, event.target.value)} value={this.props.GeneralStore[`personal_partnerCustomerFamily_${key}`]} />
                                                                        </FormControl>
                                                                    )
                                                                )
                                                            ) : (
                                                                <Typography variant="body2" className={classes.title} style={{paddingLeft: 20, alignSelf: 'flex-end'}} align='right'>
                                                                    {value}
                                                                </Typography>
                                                            )}
                                                        </div>
                                                        <Divider />
                                                    </React.Fragment>
                                                )
                                            )})}
                                        </div>
                                        {((this.props.GeneralStore.personalDataCustomerFamilyData.length - 1) != index) &&
                                            <Divider orientation="vertical" flexItem />
                                        }
                                    </React.Fragment>
                                )})}
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', paddingRight: 20, marginTop: 20}}>
                            <Typography component="h1" variant="h5" style={{color: '#256cd6', marginBottom: 10, alignSelf: 'flex-start'}} align='left'>
                                {this.context.language === 'en_US' ? 'Customer Education' : 'Thông tin giáo dục'}
                            </Typography>
                            <div style={{display: 'flex', flexDirection: 'row', width: '100%', paddingRight: 20,}}>
                                {this.props.GeneralStore.personalDataCustomerEducationData.map((data, index) => {
                                return (
                                    <React.Fragment>
                                        <div style={{display: 'flex', flexDirection: 'column', margin: 20}}>
                                            {Object.entries(data).map(([key,value],i) => {
                                                return(
                                                !(PartnerCustomerEducationKeysNotToCheck.includes(key)) && (
                                                    <React.Fragment>
                                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 10}} key={`personalPartnerConpanyExperience_${i}`}>
                                                            <Typography variant="caption" className={classes.title} style={{paddingRight: 20, alignSelf: 'center', fontWeight: 'bold'}}>
                                                                {this.context.language === 'en_US' ? PartnerCustomerEducationMapping[key] : PartnerCustomerEducationMapping_Vt[key]} {!!this.props.GeneralStore.isEditable && (
                                                                    Object.entries(this.props.GeneralStore.highlightedFields).map(([key2,value2],i) => {
                                                                        if(key === value2) {
                                                                            return (<StarRateIcon style={{color: 'red', height: 10, width: 10,}} />);
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })
                                                                )}
                                                            </Typography>
                                                            {this.props.GeneralStore.isEditable ? (
                                                                <FormControl style={{minWidth: 250}}>
                                                                    <TextField id={`outlined-basic-${key}`} label={this.context.language === 'en_US' ? PartnerCustomerEducationMapping[key] : PartnerCustomerEducationMapping_Vt[key]} variant="outlined" onChange={event => this.changeText(`personal_partnerCustomerEducation_${key}`, event.target.value)} value={this.props.GeneralStore[`personal_partnerCustomerEducation_${key}`]} />
                                                                </FormControl>
                                                            ) : (
                                                                <Typography variant="body2" className={classes.title} style={{paddingLeft: 20, alignSelf: 'flex-end'}} align='right'>
                                                                    {value}
                                                                </Typography>
                                                            )}
                                                        </div>
                                                        <Divider />
                                                    </React.Fragment>
                                                )
                                            )})}
                                        </div>
                                        {((this.props.GeneralStore.personalDataCustomerEducationData.length - 1) != index) &&
                                            <Divider orientation="vertical" flexItem />
                                        }
                                    </React.Fragment>
                                )})}
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(withStyles(styles)(TabPersonal));
