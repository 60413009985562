import React, { Component, forwardRef } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { withRouter, } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { observer, inject } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import SortIcon from '@material-ui/icons/Sort';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Dialer from './dialer'
import MaterialTable from "material-table";
import { TablePagination } from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import FilterList from '@material-ui/icons/FilterList';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { OdooContext } from "../contexts/OdooContext";
import MainNavHeader from './MainNavHeader';
import TabPersonal from './TabPersonal';
import TabHistory from './TabHistory';
import TabProduct from './TabProduct';
import TabLoan from './TabLoan';
import TabLeadscore from './TabLeadscore';
import { LeadMenu, CreateDropdownsDynamic, CreateDropdowns, availableValues, CreateDetailsMapping, CreateDetailsKey, } from './utils/Common';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';

import Autocomplete from '@material-ui/lab/Autocomplete';

import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import PropTypes from 'prop-types'
import { sipPropType, callPropType,SipProvider } from '@evercall/react-sip';

import './HomeScreen.css';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
   
}))
// const Classes = useStyles();
// const theme = useTheme();

const styles = (theme) => ({
    root: {
        display: 'flex',
        height: '100%', 
        flexGrow: 1,
        width:"100%"
        // marginTop: 13,
        // overflowY: 'auto',
        // overflowX: 'hidden',
    },
    containerSidenav: {
        borderWidth: 0,
        borderRightWidth: 2,
        borderColor: '#f1f1f1',
        borderStyle: 'solid',
        height: '100%',
        overflowY: 'show',
        overflowX: 'hidden',
        paddingBottom: 80,
    },
    containerMainnav: {
        overflowY: 'show',
        overflowX: 'hidden',
        height: '100%',
    },
    sidenavRoot: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        maxWidth: '45ch',
        marginBottom: 10,
    },
    inline: {
        display: 'inline',
    },
    tabsRoot: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        width: 250,
        overflowY: 'auto',
        marginBottom: 80,
        // minWidth: 120,
        // maxWidth: 250,
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        position:"relative",
        zIndex:9
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth+10}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        marginLeft: 45,
    },
    drawerPaper: {
        width: drawerWidth,
        marginLeft:45,
        top:65,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        position:"relative",
        zIndex:9999,
        height:0,
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    textFieldDateFilter: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

@inject('GeneralStore')
@observer
class HomeScreen extends Component {
    
    static contextType = OdooContext;

    constructor(props) {
        super(props)
        this.state = {
            value: 0,
            secondary: false,
            isPageLoading: true,
            showError: false,
            errorMessage: '',
            leadDetailsAvailable: false,
            showLeadDetailsError: true,
            leadDetailsErrorMessage: 'Please click on a sales lead to get its information',
            noLeadData: true,
            myData: [],
            origMyData: [],
            selectedListItem: null,
            clearMessageState: false,
            clearMessageStateTabs: false,
            open: false,
            age: '',
            filterDisposition: [],
            filterSubDisposition: [],
            showLeadDetailsTable: true,
            showCreate: false,
            selectedLeadType: 'leads',
            selectedLeadName: 'Leads',
            isLoadingCreate: false,
            showIsLoadingCreate: false,
            isLoadingCreateMessage: '',
            product: '',
            disposition: '',
            sub_disposition: '',
            email: '',
            campaign: '',
            national_id: '',
            sales_team: '',
            phone: '',
            name: '',
            birth_date: '',
            city: '',
            gender: '',
            marital_status: '',
            income: '',
            income_type: '',
            residence_type: '',
            annual_income: '',
            createDropdowns_sub_disposition: [],
            createDropdowns_disposition: [],
            createDropdowns_campaign: [],
            createDropdowns_sales_team: [],
            createDropdowns_product: [],
            createDropdowns_city: [],
            messageColor: 'red',
            selectedLeadIdIndex: 0,
            leadsIndexMapping: {},
            leadsIndexReverseMapping: {},
            leadsIndexMappingGroups: {},
            leadsIndexReverseMappingGroups: {},
            totalLeadsGroups: [],
            leadGroups: false,
            leadIdsGroups: [],
            drawerOpen: false,
            filtersList: [],
            selectedFilters: [],
            toDateFilter: null,
            fromDateFilter: null,
            existingMyData: [],
            incomingLeadId:''
        }
    }

    handleIncomingLeadId =()=>{
        if(this.context.incomingLeadId !== this.state.incomingLeadId){
            this.setState({incomingLeadId:this.context.incomingLeadId})
            if(this.context.incomingLeadId === 'new') {
                this.setState({phone:this.context.incomingCaller})
                this.create()
            } 
            if(this.context.incomingLeadId !== 'new'){
                this.handleListitemClick(this.context.incomingLeadId,this.context.incomingLeadId,null)
            }
        }
    }

    handleDrawerOpen = () => {
        this.setState({drawerOpen: true})
    }

    handleDrawerClose = () => {
        this.setState({drawerOpen: false})
    }

    updateClearMessageState = () => {
        this.setState((prevState) => ({clearMessageState: !prevState.clearMessageState}))
    }

    setMessgageStateTabs = () => {
        this.setState((prevState) => ({clearMessageStateTabs: !prevState.clearMessageStateTabs}))
    }

    setDateFilter = (key, value) => {
        this.setState({[key]: value});
    }

    checkDateValid = (checkDate) => {
        let d = new Date(checkDate);
        if (Object.prototype.toString.call(d) === "[object Date]") {
            if (isNaN(d.getTime())) {
                return false
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    applyDateFilter = () => {
        if(this.state.fromDateFilter && this.state.toDateFilter) {
            if(this.checkDateValid(this.state.fromDateFilter) && this.checkDateValid(this.state.toDateFilter)) {
                let currentMyData = Object.assign([], this.state.originalMyData);
                let dataToShow = [];
                
                for(let i = 0; i < currentMyData.length; i++) {
                    if((new Date(currentMyData[i].created_on).getTime() >= new Date(this.state.fromDateFilter).getTime()) && (new Date(currentMyData[i].created_on).getTime() <= new Date(this.state.toDateFilter).getTime())) {
                        dataToShow.push(currentMyData[i])
                    }
                }

                this.setState({myData: dataToShow,})
            }
        }
    }

    clearDateFilter = () => {
        let existingMyData = this.state.originalMyData;
        if(existingMyData.length > 0) {
            this.setState({myData: existingMyData, fromDateFilter: '', toDateFilter: '', existingMyData: [],});
        }
    }

    clearFilter = async () => {
        let filtersList = this.state.filtersList;
        for(let i = 0; i < filtersList.length; i++) {
            this.setState({[`filters_${filtersList[i].id}`]: false})
        }
        this.setState({selectedFilters: [],}, () => {
            this.getFilterList(this.state.token);
            this.applyFilter();
        })
    }

    applyFilter = async () => {
        let selectedFilters = this.state.selectedFilters;
        if(selectedFilters.length == 0) {
            this.handleDrawerClose();
            await this.getLeadList(this.state.token, '/api/tsr/lead_list');
        } else {
            let filtersToSend = [];
            for(let i = 0; i < selectedFilters.length; i++) {
                filtersToSend.push(selectedFilters[i].split('_')[1]);
            }

            let dataToSend = {};
            dataToSend['filter_id'] = filtersToSend;
            dataToSend['token'] = this.state.token;
            console.log(dataToSend)
            // return;

            this.setState({leadDetailsAvailable: false, showLeadDetailsError: false, leadDetailsErrorMessage: '',}, () => {
                this.handleDrawerClose();
            })

            let myResponse = null;
            try {
                myResponse = await fetch('/api/tsr/filtered_leads/update', {
                    method: 'POST',
                    body: JSON.stringify(dataToSend),
                    headers: {
                        'Content-Type': 'application/json; ',
                        Authorization: `Bearer ${this.state.token}`,
                    },
                });
            } catch (error) {
                console.log(error)
                this.setState({leadDetailsAvailable: false, showLeadDetailsError: true, leadDetailsErrorMessage: 'There was an error is getting the leads data'});
                return;
            }
            
            let jsonResp = null;
            if(myResponse.status == 200) {
                this.setState({isLoading: false})
                jsonResp = await myResponse.json();
            } else {
                console.log(myResponse)
                this.setState({leadDetailsAvailable: false, showLeadDetailsError: true, leadDetailsErrorMessage: 'There was an error is getting the leads data'});
                return;
            }

            if(jsonResp) {
                console.log("Lead Details Data: ", jsonResp)
                if(jsonResp.status == 'True') {
                    this.setState({myData: jsonResp.res, originalMyData: jsonResp.res, isPageLoading: false, showError: false, errorMessage: '', totalLeads: jsonResp.res.length, origMyData: jsonResp.res, dataToShow: jsonResp.res, showLeadDetailsTable: true, showCreate: false,leadDetailsAvailable: true, showLeadDetailsError: true, leadDetailsErrorMessage: '', noLeadData: false, isLoading: false,}, () => {
                        let leadsIndexMapping = {};
                        let leadsIndexReverseMapping = {};
                        for(let i = 0; i < jsonResp.res.length; i++) {
                            leadsIndexMapping[jsonResp.res[i].id] = i;
                            leadsIndexReverseMapping[i] = jsonResp.res[i].id;
                        }
                        this.setState({leadsIndexMapping, leadsIndexReverseMapping, totalLeads: jsonResp.res.length});
                    })

                } else {
                    this.setState({leadDetailsAvailable: false, showLeadDetailsError: true, leadDetailsErrorMessage: 'There was an error is getting the leads data'});
                }
            } else {
                this.setState({leadDetailsAvailable: false, showLeadDetailsError: true, leadDetailsErrorMessage: 'There was an error is getting the leads data'});
            }
        }
    }

    handleChangeFilter = (event) => {
        console.log(event.target.name, event.target.checked)
        let selectedFilters = this.state.selectedFilters;
        if(event.target.checked) {
            if(!(selectedFilters.includes(event.target.name))) {
                selectedFilters.push(event.target.name)
            }
        } else {
            if(selectedFilters.includes(event.target.name)) {
                selectedFilters = selectedFilters.filter(e => e !== event.target.name)
            }
        }
        this.setState({ selectedFilters, [event.target.name]: event.target.checked, }, () => {
            this.applyFilter();
        })
    }

    handleChange = (event, newValue) => {
        console.log("New Value: " + newValue)
        if (newValue == 0) {
            this.setState({personalTabClicked: true}, () => {
                this.updateClearMessageState();
            })
        } else if (newValue == 1) {
            this.setState({historyTabClicked: true}, () => {
                this.updateClearMessageState();
            })
        } else if (newValue == 2) {
            this.setState({productTabClicked: true}, () => {
                this.updateClearMessageState();
            })
        } else if (newValue == 3) {
            this.setState({loanTabClicked: true}, () => {
                this.updateClearMessageState();
            })
        } else if (newValue == 4) {
            this.setState({leadscoreTabClicked: true}, () => {
                this.updateClearMessageState();
            })
        }
        this.setState({value: newValue,})
    };

    createLeadRedirect = async () => {
        await this.getLeadList(this.state.token, '/api/tsr/lead_list',true);
        let leadIDs = this.state.myData;
        let maxLeadId = -1;
        for(let i = 0; i < leadIDs.length; i++) {
            if(leadIDs[i].id > maxLeadId) {
                maxLeadId = leadIDs[i].id;
            }
        }
        this.handleListitemClick(maxLeadId, maxLeadId,null);
    }

    createLead = async () => {
        this.setState({isLoadingCreate: true, showIsLoadingCreate: true, isLoadingCreateMessage: ''})

        let dataToSend = {}
        CreateDetailsKey.forEach((arrayItem) => {
            for(let item in arrayItem) {
                dataToSend[arrayItem[item]] = this.state[arrayItem[item]];
            }
        });
        dataToSend.token = this.state.token;
        
        await fetch('/api/tsr/create_lead', {
            method: 'POST',
            body: JSON.stringify(dataToSend),
            headers: {
                'Content-Type': 'application/json; ',
                Authorization: `Bearer ${this.state.token}`,
            },
        })
        .then(response => {
            if(response.status == 200) {
                return response.json()
            } else {
                return "NA"
            }
        })
        .then(data => {
            if(data == "NA") {
                this.setState({isLoadingCreate: true, showIsLoadingCreate: false, isLoadingCreateMessage: 'There was some error in creating the data!', messageColor: 'red'})
            } else {
                if(data.status == 'True') {
                    this.setState({isLoadingCreate: true, showIsLoadingCreate: false, isLoadingCreateMessage: data.res, messageColor: 'green'}, () => {
                        CreateDetailsKey.forEach((arrayItem) => {
                            for(let item in arrayItem) {
                                this.setState({[arrayItem[item]]: ''});
                            }
                        });
                        this.createLeadRedirect();
                    })
                } else {
                    this.setState({isLoadingCreate: true, showIsLoadingCreate: false, isLoadingCreateMessage: data.res, messageColor: 'red'})
                }
            }
        })
        .catch(error => {
            console.log(error)
            this.setState({isLoadingCreate: true, showIsLoadingCreate: false, isLoadingCreateMessage: 'There was some error in creating the data!', messageColor: 'red'})
        });
    }

    changeText = (key, myValue) => {
        if(key == 'disposition') {
            let createDropdowns_sub_disposition = [];
            let all_sub_disposition_data = this.state.createDropdowns_all_sub_disposition_list;
            for(let i = 0; i < all_sub_disposition_data.length; i++) {
                if(myValue == all_sub_disposition_data[i].disposition) {
                    createDropdowns_sub_disposition.push(all_sub_disposition_data[i])
                }
            }
            this.setState({createDropdowns_sub_disposition});
        } else if (key == 'sales_team') {
            let createDropdowns_campaign = [];
            let createDropdowns_campaign_all = this.state.createDropdowns_campaign_all;
            for(let i = 0; i < createDropdowns_campaign_all.length; i++) {
                if(myValue == createDropdowns_campaign_all[i].sales_team) {
                    createDropdowns_campaign.push(createDropdowns_campaign_all[i])
                }
            }
            this.setState({createDropdowns_campaign});
        }

        this.setState({[key]: myValue})
    }

    create = () => {
        this.setState({showLeadDetailsTable: false, showCreate: true,leadDetailsAvailable: true, showLeadDetailsError: true, leadDetailsErrorMessage: '', noLeadData: false, isLoading: true, isLoadingCreate: false, showIsLoadingCreate: false, isLoadingCreateMessage: ''})
    }

    goNext = () => {
        this.props.GeneralStore.cancelEdit();

        let leadsReverseMapping;
        if(this.state.leadGroups) {
            leadsReverseMapping = this.state.leadsIndexReverseMappingGroups;
        } else {
            leadsReverseMapping = this.state.leadsIndexReverseMapping;
        }
        let selectedLeadIdIndex = this.state.selectedLeadIdIndex;
        let nextLeadIndex = selectedLeadIdIndex + 1;
        if(nextLeadIndex in leadsReverseMapping) {
            let nextLeadId = leadsReverseMapping[nextLeadIndex];
            this.handleListitemClick(nextLeadId, nextLeadId,null);
        }
    }

    goPrevious = () => {
        this.props.GeneralStore.cancelEdit();
        
        let leadsReverseMapping;
        if(this.state.leadGroups) {
            leadsReverseMapping = this.state.leadsIndexReverseMappingGroups;
        } else {
            leadsReverseMapping = this.state.leadsIndexReverseMapping;
        }
        let selectedLeadIdIndex = this.state.selectedLeadIdIndex;
        let previousLeadIndex = selectedLeadIdIndex - 1;
        if(previousLeadIndex in leadsReverseMapping) {
            let nextLeadId = leadsReverseMapping[previousLeadIndex];
            this.handleListitemClick(nextLeadId, nextLeadId,null);
        }
    }

    goBack = () => {
        let selectedLeadTypeUrl = '';
        LeadMenu.forEach((menuItem) => {
            if(menuItem.type == this.state.selectedLeadType) {
                selectedLeadTypeUrl = menuItem.url;
            }
        });
        sessionStorage.removeItem('selectedLeads');
        this.setState({showLeadDetailsTable: true, showCreate: false,leadDetailsAvailable: true, showLeadDetailsError: true, leadDetailsErrorMessage: '', noLeadData: false, isLoading: true, leadGroups: false, leadsIndexMappingGroups: {}, leadsIndexReverseMappingGroups: {}, totalLeadsGroups: 0, leadIdsGroups: [], toDateFilter: '', fromDateFilter: '', existingMyData: [],}, () => {
            this.getLeadList(this.state.token, selectedLeadTypeUrl);
        })
    }

    goBackCreate = () => {
        let selectedLeadTypeUrl = '';
        LeadMenu.forEach((menuItem) => {
            if(menuItem.type == this.state.selectedLeadType) {
                selectedLeadTypeUrl = menuItem.url;
            }
        });
        this.setState({showLeadDetailsTable: true, showCreate: false,leadDetailsAvailable: true, showLeadDetailsError: true, leadDetailsErrorMessage: '', noLeadData: false, isLoading: true, isLoadingCreate: false, isLoadingCreateMessage: '', showIsLoadingCreate: false, toDateFilter: '', fromDateFilter: '', existingMyData: [],}, () => {
            this.getLeadList(this.state.token, selectedLeadTypeUrl);
        })
    }

    handleListitemClick = async (clickedItemId, selectedIndex,phone) => {
        console.log(clickedItemId, selectedIndex)
        if (clickedItemId===undefined || clickedItemId=="" || clickedItemId===null)
        {
            return;
        }
        this.props.GeneralStore.setGeneralReload(true)
        this.props.GeneralStore.setPersonalReload(true)
        this.props.GeneralStore.setLoanInfoReload(true)
        this.setState({phone:phone})
        let selectedLeads = sessionStorage.getItem("selectedLeads")
        
        let selectedLeadIdIndex = 0;
        if(selectedLeads) {
            if(this.state.leadGroups) {
                selectedLeadIdIndex = this.state.leadsIndexMappingGroups[selectedIndex];
            } else {
                let jsonData = JSON.parse(selectedLeads);
                let totalLeadsGroups = jsonData.length;
                let leadIdsGroups = [];
                for(let i = 0; i < totalLeadsGroups; i++) {
                    leadIdsGroups.push(jsonData[i].id);
                }
                let leadsIndexMappingGroups = {};
                let leadsIndexReverseMappingGroups = {};
                for(let i = 0; i < totalLeadsGroups; i++) {
                    leadsIndexMappingGroups[jsonData[i].id] = i;
                    leadsIndexReverseMappingGroups[i] = jsonData[i].id;
                }
                
                selectedLeadIdIndex = leadsIndexMappingGroups[selectedIndex];

                this.setState({leadsIndexMappingGroups, leadsIndexReverseMappingGroups, totalLeadsGroups, leadIdsGroups, leadGroups: true,}, () => {
                    console.log(leadsIndexMappingGroups, leadsIndexReverseMappingGroups, totalLeadsGroups)
                });
            }
        } else {
            selectedLeadIdIndex = this.state.leadsIndexMapping[selectedIndex]
        }

        this.setState({selectedListItem: selectedIndex, selectedLeadId: clickedItemId, leadDetailsAvailable: false, showLeadDetailsError: false, leadDetailsErrorMessage: '', noLeadData: false, selectedLeadIdIndex})

        let myResponse = null; 
        try {
            myResponse = await fetch(`/api/tsr/lead_details?lead_id=${clickedItemId}&lang=${this.context.language}`);
        } catch (error) {
            console.log(error)
            this.setState({leadDetailsAvailable: false, showLeadDetailsError: true, leadDetailsErrorMessage: 'Some error occured while logging you in.', noLeadData: false})
            return;
        }
        
        let jsonResp = null;
        if(myResponse.status == 200) {
            jsonResp = await myResponse.json();
        } else {
            console.log(myResponse)
            this.setState({leadDetailsAvailable: false, showLeadDetailsError: true, leadDetailsErrorMessage: 'Some error occured while fetching the lead details.', noLeadData: false})
            return;
        }

        if(jsonResp) {
            if(jsonResp.status == 'True') {
                this.setState({leadDetailsAvailable: true, showLeadDetailsError: true, leadDetailsErrorMessage: '', noLeadData: false, isLoading: false, showLeadDetailsTable: false, showCreate: false,})
            } else {
                this.setState({leadDetailsAvailable: false, showLeadDetailsError: true, leadDetailsErrorMessage: 'Some error occured while fetching the lead details.', noLeadData: false, isLoading: false})
            }
        } else {
            this.setState({leadDetailsAvailable: false, showLeadDetailsError: true, leadDetailsErrorMessage: 'Some error occured while fetching the lead details.', noLeadData: false, isLoading: false})
        }
    }

    getLeadList = async (token, url,fresh=false) => {
        this.setState({leadDetailsAvailable: false, showLeadDetailsError: false, leadDetailsErrorMessage: ''})

        let myResponse = null;

		try {
            if(fresh){
            myResponse = await fetch(`${url}/?token=${token}`,
            {headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0',
            }}
            );
        }
        else
        {
            myResponse = await fetch(`${url}/?token=${token}`);

        }
		} catch (error) {
            console.log(error)
            this.setState({leadDetailsAvailable: false, showLeadDetailsError: true, leadDetailsErrorMessage: 'There was an error is getting the leads data'});
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			this.setState({isLoading: false})
			jsonResp = await myResponse.json();
		} else {
            console.log(myResponse)
            this.setState({leadDetailsAvailable: false, showLeadDetailsError: true, leadDetailsErrorMessage: 'There was an error is getting the leads data'});
			return;
		}

		if(jsonResp) {
			console.log("Lead Details Data: ", jsonResp)
			if(jsonResp.status === 'True') {
                this.context.setLeadData(jsonResp.leads)
                this.setState({myData: jsonResp.leads, originalMyData: jsonResp.leads, isPageLoading: false, showError: false, errorMessage: '', totalLeads: jsonResp.leads.length, origMyData: jsonResp.leads, dataToShow: jsonResp.leads, showLeadDetailsTable: true, showCreate: false,leadDetailsAvailable: true, showLeadDetailsError: true, leadDetailsErrorMessage: '', noLeadData: false, isLoading: false,}, () => {
                    let leadsIndexMapping = {};
                    let leadsIndexReverseMapping = {};
                    for(let i = 0; i < jsonResp.leads.length; i++) {
                        leadsIndexMapping[jsonResp.leads[i].id] = i;
                        leadsIndexReverseMapping[i] = jsonResp.leads[i].id;
                    }
                    this.setState({leadsIndexMapping, leadsIndexReverseMapping, totalLeads: jsonResp.leads.length});
                })

			} else {
                this.setState({leadDetailsAvailable: false, showLeadDetailsError: true, leadDetailsErrorMessage: 'There was an error is getting the leads data'});
            }
		} else {
            this.setState({leadDetailsAvailable: false, showLeadDetailsError: true, leadDetailsErrorMessage: 'There was an error is getting the leads data'});
        }
    }

    showLeadDetailsTable = (type, name, url) => {
        let token = this.state.token;
        this.setState({selectedLeadType: type, selectedLeadName: name}, () => {
            this.getLeadList(token, url);
        });
    }

    getCityList = async (token) => {
        let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/city_list/?token=${token}`);
		} catch (error) {
			console.log(error)
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			this.setState({isLoading: false})
			jsonResp = await myResponse.json();
		} else {
			console.log(myResponse)
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                let createDropdowns_city = jsonResp.res;
                let city_column = {};
                for(let i = 0; i < createDropdowns_city.length; i++) {
                    city_column[createDropdowns_city[i].name] = createDropdowns_city[i].name;
                }
                this.setState({createDropdowns_city, city_column})
			} else {
                console.log(jsonResp)
            }
		} else {
            console.log(jsonResp)
        }
    }

    getProductList = async (token) => {
        let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/product_list/?token=${token}`);
		} catch (error) {
			console.log(error)
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			jsonResp = await myResponse.json();
		} else {
			console.log(myResponse)
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                let createDropdowns_product = jsonResp.res;
                console.log("Product: ", createDropdowns_product)
                this.setState({createDropdowns_product})
			} else {
                console.log(jsonResp)
            }
		} else {
            console.log(jsonResp)
        }
    }

    getCampaignAndSales = async (token) => {
        let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/sales_team_list/?token=${token}`);
		} catch (error) {
			console.log(error)
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			jsonResp = await myResponse.json();
		} else {
			console.log(myResponse)
			return;
		}

		if(jsonResp) {
            console.log("Adi", jsonResp);
			if(jsonResp.status == 'True') {
                let createDropdowns_sales_team = jsonResp.res[0].team_ist;
                let createDropdowns_campaign_all = jsonResp.res[0].campaign_list_create;
                let createDropdowns_campaign_table = jsonResp.res[0].camp_list;
                let campaign_column = {};
                for(let i = 0; i < createDropdowns_campaign_table.length; i++) {
                    campaign_column[createDropdowns_campaign_table[i].name] = createDropdowns_campaign_table[i].name;
                }
              
                this.setState({createDropdowns_sales_team, createDropdowns_campaign_all, campaign_column})
			} else {
                console.log(jsonResp)
            }
		} else {
            console.log(jsonResp)
        }
    }

    getSubDispositionList = async (token) => {
        let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/subdisposition_list/?token=${token}`);
		} catch (error) {
			console.log(error)
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			jsonResp = await myResponse.json();
		} else {
			console.log(myResponse)
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                let all_sub_disposition = jsonResp.sub_dispo_list;
                let createDropdowns_all_sub_disposition_list = all_sub_disposition;
                let subDisposition_column = {};
                for(let i = 0; i < all_sub_disposition.length; i++) {
                    subDisposition_column[all_sub_disposition[i].name] = all_sub_disposition[i].name;
                }
                this.setState({all_sub_disposition, subDisposition_column, createDropdowns_all_sub_disposition_list})
			} else {
                console.log(jsonResp)
            }
		} else {
            console.log(jsonResp)
        }
    }

    getDispositionList = async (token) => {
        let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/disposition_list/?token=${token}`);
		} catch (error) {
			console.log(error)
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			jsonResp = await myResponse.json();
		} else {
			console.log(myResponse)
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                let all_disposition = jsonResp.dispo_list;
                let createDropdowns_disposition = all_disposition;
                let disposition_column = {};
                for(let i = 0; i < all_disposition.length; i++) {
                    disposition_column[all_disposition[i].name] = all_disposition[i].name;
                }
                this.setState({all_disposition, disposition_column, createDropdowns_disposition})
			} else {
                console.log(jsonResp)
            }
		} else {
            console.log(jsonResp)
        }
    }

    getColor(stage) {
        let color = '#bde0eb';
        switch(stage) {
            case 'SA':
                color = '#bde0eb';
                break;
            case 'FPC':
                color = '#FFDF00';
                break;
            case 'QA DOC':
                color = '#D3D3D3';
                break;
            case 'REJECT':
                color = '#ffcccb';
                break;
            case 'CUSTOMER':
                color = '#C7F6B6';
                break;
            case 'QA':
                color = '#e00000';
                break;
            default:
                color = '#fff';
        }
        
        return color;
    }

    getFilterList = async (token) => {
        let myResponse = null;
		try {
            myResponse = await fetch(`/api/tsr/filters_list/?token=${token}`);
		} catch (error) {
			console.log(error)
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			jsonResp = await myResponse.json();
		} else {
			console.log(myResponse)
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                this.setState({filtersList: [],}, () => {
                    this.setState({filtersList: jsonResp.res});
                })
			} else {
                console.log(jsonResp)
            }
		} else {
            console.log(jsonResp)
        }
    }

    getToken = async () => {
        let token = await localStorage.getItem('AccessToken');
        if(token && token != 'undefined') {
            console.log("Token: ",token)
            this.context.setToken(token)
            return token;
        } else {
            this.context.setUserName('');
		    this.context.setLoginStatus(false);
            localStorage.removeItem('AccessToken');
            localStorage.removeItem('UserLanguage');
            localStorage.removeItem('Username');
            setTimeout(() => {
                this.props.history.push('login');
            }, 1000);
        }
    }

    async componentDidMount() {
        this.props.setUserAuthenticated(true)
        sessionStorage.removeItem('selectedLeads');
        let token = await this.getToken();
        this.setState({token})
        let username = localStorage.getItem('Username');
        this.context.setUserName(username);
        this.context.setLoginStatus(true);
        await this.getFilterList(token);
        await this.getDispositionList(token);
        await this.getSubDispositionList(token);
        await this.getCampaignAndSales(token);
        await this.getProductList(token);
        await this.getCityList(token);
        await this.getLeadList(token, '/api/tsr/lead_list');
        this.handleIncomingLeadId()
        this.setState({phone:''})
    }

    componentDidUpdate(){
        this.handleIncomingLeadId()
    }

    render() {
        const { classes,theme} = this.props;
        return (
            <div className={classes.root}>
                <div style={{ width: "100%", overflow: "scroll", height: '100%'}}>
                    <CssBaseline />
                    <AppBar
                        position="fixed"
                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: this.state.drawerOpen,
                        })}
                    >
                        <Toolbar style={{backgroundColor:"#256cd6"}} >
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.handleDrawerOpen}
                                edge="start"
                                className={clsx(classes.menuButton, this.state.drawerOpen && classes.hide)}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        open={this.state.drawerOpen}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <div className={classes.drawerHeader}>
                            <IconButton onClick={this.handleDrawerClose}>
                                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        </div>
                        <Divider />
                        <Divider />
                        <div style={{height: '100%', overflowY: 'auto'}}>
                            <List className={classes.sidenavRoot}>
                                {LeadMenu.map((data, index) => 
                                    <>
                                        <MenuItem alignItems="flex-start" justifyContent="flex-start" onClick={() => this.showLeadDetailsTable(data.type, data.name, data.url)} button selected={this.state.selectedLeadType == data.type}>
                                            <ListItemAvatar>
                                                <Avatar alt={data.name}/>
                                            </ListItemAvatar>
                                            <ListItemText
                                                style={{alignSelf: 'center'}}
                                                primary={data.name}
                                            />
                                        </MenuItem>
                                        <Divider variant="inset" component="li" />
                                    </>
                                )}
                            </List>
                            <Divider />
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormLabel component="legend">Filters</FormLabel>
                                <Button color="primary" onClick={this.clearFilter}>Clear</Button>
                                <FormGroup columns style={{paddingLeft: 10, marginTop: 10}}>
                                    {this.state.filtersList.map((data, index) => 
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state[`filters_${data.id}`]}
                                                    onChange={this.handleChangeFilter}
                                                    name={`filters_${data.id}`}
                                                    color="primary"
                                                />
                                            }
                                            label={data.name}
                                        />
                                    )}
                                </FormGroup>
                            </FormControl>
                        </div>
                    </Drawer>
                    <main
                        className={clsx(classes.content, {
                            [classes.contentShift]: this.state.drawerOpen,
                        })}
                        className={this.state.drawerOpen?classes.content:classes.contentShift}
                    >
                        {!this.state.leadDetailsAvailable ? (
                            this.state.showLeadDetailsError ? (
                                <div style={{flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', height: '80%', justifyContent: 'center', marginTop: '20%', }}>
                                    <div style={{marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', color: this.state.noLeadData ? '#3f51b5' : 'red'}}>
                                        <span style={{fontSize: 25}}>{this.state.leadDetailsErrorMessage}</span>
                                    </div>
                                </div>
                            ) : (
                                <div style={{flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', height: '80%', justifyContent: 'center', marginTop: '20%', }}>
                                    <CircularProgress color='#256cd6' size='50px' style={{color: '#256cd6'}} />
                                    <Typography component="h1" variant="h5" style={{color: '#256cd6'}}>
                                        {this.context.language == 'en_US' ? 'Please wait while we load the data...' : 'Vui lòng đợi trong khi chúng tôi tải dữ liệu...'}
                                    </Typography>
                                </div>
                            )
                        ) : (
                            this.state.showLeadDetailsTable ? (
                                <React.Fragment>
                                    <div style={{ alignItems: 'flex-start', justifyContent: 'flex-start', alignSelf: 'flex-start', padding: 20, }}>
                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                            <Button variant="contained" color="primary" onClick={this.create}>{this.context.language == 'en_US' ? 'Create Lead' : 'tạo nên lead'}</Button>
                                            <div style={{display: 'flex', flexDirection: 'row', marginRight: 30,}}>
                                                <span style={{fontSize: 15, alignSelf: 'center'}}>Created Date:  </span>
                                                <TextField id="startDate" label="From" type="date" className={classes.textFieldDateFilter}InputLabelProps={{shrink: true,}} onChange={event => this.setDateFilter('fromDateFilter', event.target.value)} value={this.state.fromDateFilter} />
                                                <TextField id="endDate" label="To" type="date" className={classes.textFieldDateFilter} InputLabelProps={{shrink: true,}} onChange={event => this.setDateFilter("toDateFilter", event.target.value)} value={this.state.toDateFilter} />
                                                <Button color="primary" onClick={this.applyDateFilter}>{this.context.language == 'en_US' ? 'Apply' : 'Apply'}</Button>
                                                <Button color="secondary" onClick={this.clearDateFilter}>{this.context.language == 'en_US' ? 'Clear' : 'Clear'}</Button>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ maxWidth: "100%", marginRight: '60px', maxHeight: '90%', marginBottom: '80px', overflowY: 'auto' }}>
                                        <MaterialTable
                                            icons={tableIcons}
                                            columns={[
                                                { title: this.context.language == 'en_US' ? "ID" : 'TÔI', field: "id" },
                                                { title: this.context.language == 'en_US' ? "Name" : 'Tên', field: "name" },
                                                { title: this.context.language == 'en_US' ? "City" : 'Tp.', field: "city", lookup: this.state.city_column },
                                                { title: this.context.language == 'en_US' ? "Phone" : 'Điện thoại', field: "phone" },
                                                { title: this.context.language == 'en_US' ? "Campaign" : 'Chiến dịch', field: "campaign", lookup: this.state.campaign_column },
                                                { title: this.context.language == 'en_US' ? "Created Date" : 'ngày tạo ra', field: "created_on", type: 'date' },
                                                { title: this.context.language == 'en_US' ? "Appointment Date" : 'Ngày hẹn', field: "appo_date", type: 'date' },
                                                { title: this.context.language == 'en_US' ? "Disposition" : 'Bố trí', field: "dispsoition", lookup: this.state.disposition_column },
                                                { title: this.context.language == 'en_US' ? "Sub Disposition" : 'Bố trí phụ', field: "sub_disposition", lookup: this.state.subDisposition_column },
                                                { title: this.context.language == 'en_US' ? "Stage" : 'Stage', field: "stage_id" },
                                                { title: this.context.language == 'en_US' ? "Lead Source" : 'Lead Source', field: "lead_source" },

                                            ]}
                                            data={this.state.myData}
                                            options={{
                                                filtering: true,
                                                draggable: true,
                                                grouping: true,
                                                pageSize: 80,
                                                pageSizeOptions: [10, 20, 50, 80, 100, 200],
                                                rowStyle: {
                                                    fontSize: '15px',
                                                },
                                                headerStyle: {
                                                    // backgroundColor: '#256cd6',
                                                    color: '#000'
                                                }
                                            }}
                                            onRowClick={(event, rowData) => {
                                                this.handleListitemClick(rowData.id, rowData.id, rowData.phone);
                                            }}
                                            components={{
                                                Pagination: props => {
                                                    return (
                                                        <td>
                                                            <table style={{position: 'fixed', bottom: 0, left: 0, width: '100%', }} >
                                                                <tbody>
                                                                    <tr>
                                                                        <TablePagination {...props} />
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    );
                                                },
                                                Row: props => {
                                                    return (
                                                        <tr style={{backgroundColor: this.getColor(props.data.stage_id), borderBottom: 1, borderBottomStyle: 'solid', borderBottomColor: 'rgba(224, 224, 224, 1)', fontSize: '0.875rem', textAlign: 'left', letterSpacing: '0.01071em', padding: '9px', cursor: 'pointer'}} onClick={(event) => props.onRowClick(event, props.data)}>
                                                            {props.columns.map((column) => (
                                                                <td style={{padding: '16px'}}>
                                                                    {props.data[column.field]}
                                                                </td>
                                                            ))}
                                                        </tr>
                                                    )
                                                },
                                            }}
                                            title={this.context.language == 'en_US' ? "Lead Details" : 'Chi tiết khách hàng tiềm năng'}
                                        />
                                    </div>
                                </React.Fragment>
                            ) : (
                                this.state.showCreate ? (
                                    <React.Fragment>
                                        <Button color="primary" onClick={this.goBackCreate}>Go Back</Button>
                                        
                                        {this.state.isLoadingCreate && 
                                            this.state.showIsLoadingCreate ? (
                                                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', marginTop: 20, }}>
                                                    <CircularProgress color='#256cd6' size='30px' style={{color: '#256cd6'}} />
                                                    <Typography variant="body2" style={{color: '#256cd6'}}>
                                                        {this.context.language == 'en_US' ? 'Please wait while we save the data...' : 'Vui lòng đợi trong khi chúng tôi lưu dữ liệu...'}
                                                    </Typography>
                                                </div>
                                            ) : (
                                                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center'}}>
                                                    <div style={{marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', color: this.state.noLeadData ? '#3f51b5' : 'red'}}>
                                                        <span style={{fontSize: 20, color: this.state.messageColor}}>{this.state.isLoadingCreateMessage}</span>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        <div style={{display: 'flex', flexDirection: 'column', margin: 10, height: '100%', justifyContent: 'center', alignItems: 'center'}} key='createLeadsKey'>
                                            {CreateDetailsKey.map((data, index) => {
                                                return (
                                                    <React.Fragment>
                                                        <div style={{display: 'flex', flexDirection: 'row', margin: 10,}}>
                                                            {data.map((key, index) => {
                                                                return (
                                                                    <FormControl style={{margin: 20, width: 250}}>
                                                                        {CreateDropdowns.includes(key) ? (
                                                                            <FormControl style={{width: 250}}>
                                                                                <Autocomplete
                                                                                    labelId={`createDropdowns_${key}-select-label`}
                                                                                    id={`createDropdowns_${key}-select-label`}
                                                                                    options={CreateDropdownsDynamic.includes(key) ? this.state[`createDropdowns_${key}`] : availableValues[key]}
                                                                                    getOptionLabel={(option) => option.name}
                                                                                    onChange={(event, value) => {this.changeText(key, CreateDropdownsDynamic.includes(key) ? (value ? value.id : null) : (value ? value.value : null))}}
                                                                                    renderInput={(params) => <TextField {...params}  variant="outlined" label={CreateDetailsMapping[key]} />}
                                                                                    autoComplete={false}
                                                                                />
                                                                            </FormControl>
                                                                        ) : (
                                                                            <TextField 
                                                                            id={`outlined-basic-${key}`} 
                                                                            label={CreateDetailsMapping[key]} 
                                                                            type={key == 'birth_date' ? 'date' : 'default'} 
                                                                            variant="outlined" className={classes.textField} 
                                                                            InputLabelProps={{shrink: true,}} 
                                                                            onChange={event => this.changeText(key, event.target.value)} 
                                                                            value={this.state[`${key}`]} />
                                                                        )}
                                                                    </FormControl>
                                                                )
                                                            })}
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })}

                                            <Button variant="contained" size="large" style={{margin: 10, marginBottom: 50,}} color="primary" onClick={this.createLead}>{this.context.language == 'en_US' ? 'Create' : 'tạo nên'}</Button>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                                            <Button color="primary" onClick={this.goBack}>{this.context.language == 'en_US' ? 'Go Back' : 'Quay lại'}</Button>
                                            <div style={{display: 'flex', flexDirection: 'row', marginRight: 40, alignItems: 'center', justifyContent: 'center'}}>
                                                <Tooltip title="Previous">
                                                    <IconButton aria-label="previous" onClick={this.goPrevious} disabled={this.context.disablePrev}>
                                                        <ChevronLeft color="primary" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography variant="body2" style={{color: '#256cd6', marginLeft: 10, marginRight: 5,}}>
                                                    {this.state.leadGroups ? (
                                                        `${(this.state.selectedLeadIdIndex)+1}/${this.state.totalLeadsGroups}`
                                                    ) : (
                                                        `${(this.state.selectedLeadIdIndex)+1}/${this.state.totalLeads}`
                                                    )}
                                                </Typography>
                                                <Tooltip title="Next">
                                                    <IconButton aria-label="next" onClick={this.goNext} disabled={this.context.disableNext}>
                                                        <ChevronRight color="primary" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>

                                        <MainNavHeader 
                                        phone={this.state.phone}
                                        name={this.state.name} email={this.state.email} selectedLeadId={this.state.selectedLeadId} clearMessageState={this.state.clearMessageState} setMessgageStateTabs={this.setMessgageStateTabs} leadsIndexMapping={this.state.leadsIndexMapping} leadsIndexReverseMapping={this.state.leadsIndexReverseMapping} />
                                        
                                        <div className={classes.tabsRoot}>
                                            <Tabs
                                                value={this.state.value}
                                                onChange={this.handleChange}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                variant="scrollable"
                                                scrollButtons="auto"
                                                aria-label="scrollable auto tabs example"

                                            >
                                                <Tab label="Personal Details" {...a11yProps(0)} />
                                                <Tab label="History" {...a11yProps(1)} />
                                                <Tab label="Product Details" {...a11yProps(2)} />
                                                <Tab label="Loan, Bills And Statements" {...a11yProps(3)} />
                                                <Tab label="Lead Score" {...a11yProps(4)} />
                                            </Tabs>

                                            <TabPanel value={this.state.value} index={0} style={{width: '100%', height: '100%', overflowY: 'scroll', marginBottom: 50, marginRight: 50,}}>
                                                <TabPersonal selectedLeadId={this.state.selectedLeadId} personalTabClicked={this.state.personalTabClicked} clearMessageStateTabs={this.state.clearMessageStateTabs} />
                                            </TabPanel>

                                            <TabPanel value={this.state.value} index={1} style={{width: '100%', height: '100%', overflowY: 'scroll', marginBottom: 50, marginRight: 50,}}>
                                                <TabHistory selectedLeadId={this.state.selectedLeadId} historyTabClicked={this.state.historyTabClicked} clearMessageStateTabs={this.state.clearMessageStateTabs} />
                                            </TabPanel>

                                            <TabPanel value={this.state.value} index={2} style={{width: '100%', height: '100%', overflowY: 'scroll', marginBottom: 50, marginRight: 50,}}>
                                                <TabProduct selectedLeadId={this.state.selectedLeadId} productTabClicked={this.state.productTabClicked} clearMessageStateTabs={this.state.clearMessageStateTabs} />
                                            </TabPanel>

                                            <TabPanel value={this.state.value} index={3} style={{width: '100%', height: '100%', overflowY: 'scroll', marginBottom: 50, marginRight: 50,}}>
                                                <TabLoan selectedLeadId={this.state.selectedLeadId} loanTabClicked={this.state.loanTabClicked} clearMessageStateTabs={this.state.clearMessageStateTabs} />
                                            </TabPanel>

                                            <TabPanel value={this.state.value} index={4} style={{width: '100%', height: '100%', overflowY: 'scroll', marginBottom: 50, marginRight: 50,}}>
                                                <TabLeadscore selectedLeadId={this.state.selectedLeadId} leadscoreTabClicked={this.state.leadscoreTabClicked} clearMessageStateTabs={this.state.clearMessageStateTabs} />
                                            </TabPanel>
                                        </div>
                                    </React.Fragment>
                                )
                            )
                        )}
                    </main>
                </div>
                {/* <SipProvider
					  host='callserver1.thevoicemate.com'
					  port={8089}
					  user='9001'
					  pathname="/ws"
					  password='f61818731087112de9045203b2c7bbe7'
					  autoRegister={true} // true by default, see jssip.UA option register
					  autoAnswer={false} // automatically answer incoming calls; false by default
					  iceRestart={false} // force ICE session to restart on every WebRTC call; false by default
					  sessionTimersExpires={120} // value for Session-Expires header; 120 by default
					  debug={true} // whether to output events to console; false by default
					  inboundAudioDeviceId={"default"} // default, or a deviceId obtained from navigator.mediaDevices.enumerateDevices() where kind is "audioinput"
					  outboundAudioDeviceId={"default"} // default, or a deviceId obtained from navigator.mediaDevices.enumerateDevices() where kind is "audiooutput" 
					  >
				<Dialer />
				</SipProvider> */}
            </div>
        );
    }
}

function Homy(props){
    const theme= useTheme()
    return <HomeScreen {...props} theme={theme} />
}


export default withRouter(withStyles(styles)(Homy));
