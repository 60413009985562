import React, { Component } from "react";
import { withRouter, } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from "@material-ui/core/IconButton";
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import FormGroup from '@material-ui/core/FormGroup';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { observer, inject } from 'mobx-react';
import { OdooContext } from "../contexts/OdooContext";
import { GeneralDetailsMapping, GeneralDetailsMapping_Vt, GeneralDetailsDropdowns, GeneralDetailsToSaveMapping, availableValues } from './utils/Common';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from "@date-io/date-fns";
import StarRateIcon from '@material-ui/icons/StarRate';

import {
    DateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";

const { convertToTimeZone } = require('date-fns-timezone')

const styles = (theme) => ({
    sidenavRoot: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        maxWidth: '45ch',
        marginBottom: 80,
    },
    inline: {
        display: 'inline',
    },
    textField: {
        width: 200,
    },
    title: {
        maxWidth: 200,
    }
})

@inject('GeneralStore')
@observer
class ManinNavHeader extends Component {

    static contextType = OdooContext;

    constructor(props) {
        super(props);
        this.state = {
            isPageLoading: true,
            showPageLoadingMessage: false,
            pageLoadingMessage: '',
            isLoading: false,
            showMessage: false,
            message: '',
            showHangup: false,
            emptyString: '',
            generalIsLoading: false,
            generalIsLoadingMessage: 'Please wait while we save the data...',
            generalShowMessage: false,
            generalMessage: '',
            generalData: [],
            generalDataKeys: [],
            all_sub_disposition: [],
            all_sub_disposition_data: [],
            rowsVisits: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            timezone: 'Asia/Ho_Chi_Minh',
            appointmentDateEditable: false,
            isSavedData: false,
            interestedId: null,
            interestedId: null,
            all_disposition_toShow: [],
            showConvertToQA: false,
        }

    }

    startCalling=()=>{
        this.context.setIncomingCaller(`${this.props.phone}`)
        this.context.setStartCall(true)
        this.context.setShowDialer(true)
    }


    startCall = async () => {
        this.setState({isLoading: true, showMessage: false, message: ''}, () => {
            this.props.setMessgageStateTabs();
        })

        await fetch(`/api/tsr/start_call?lead_id=${this.props.selectedLeadId}&token=${this.state.token}`)
        .then(response => {
            if(response.status == 200) {
                return response.json()
            } else {
                return "NA"
            }
        })
        .then(data => {
            if(data == "NA") {
                this.setState({isLoading: true, showMessage: true, message: 'There was some error while making the call', callMessageColor: 'red'})
            } else {
                if(data.status == 'True') {
                    this.setState({showHangup: true, isLoading: false, showMessage: false, message: '', callMessageColor: 'green'}, () => {
                        this.getGeneralData();
                        this.context.changePrevNext(true);
                        this.toggleEdit(true);
                    })
                } else {
                    this.setState({isLoading: true, showMessage: true, message: data.msg, callMessageColor: 'red'})
                }
            }
        })
        .catch(error => {
            console.log(error)
            this.setState({isLoading: true, showMessage: true, message: 'There was some error while making the call', callMessageColor: 'red'})
        })
    }

    endCall = async () => {
        return false;//aditya
        await this.saveGeneralData();

        if(!this.state.isSavedData) {
            return false;
        }

        this.setState({isLoading: true, showMessage: false, message: '', isSavedData: false,})

        await fetch(`/api/tsr/end_call?lead_id=${this.props.selectedLeadId}&token=${this.state.token}`)
        .then(response => {
            if(response.status == 200) {
                return response.json()
            } else {
                return "NA"
            }
        })
        .then(data => {
            if(data == "NA") {
                this.setState({isLoading: true, showMessage: true, message: 'There was some error while disconnecting the call', callMessageColor: 'red'})
            } else {
                if(data.status == 'True') {
                    this.setState({showHangup: false, isLoading: true, showMessage: true, message: data.msg, callMessageColor: 'green'}, () => {
                        this.getGeneralData();
                        this.context.changePrevNext(false);
                        this.toggleEdit(false);
                    })
                } else {
                    this.setState({isLoading: true, showMessage: true, message: data.msg, callMessageColor: 'red'})
                }
            }
        })
        .catch(error => {
            console.log(error)
            this.setState({isLoading: true, showMessage: true, message: 'There was some error while disconnecting the call', callMessageColor: 'red'})
        })
    }

    convertToQA = async () => {
        this.setState({isLoading: true, showMessage: false, message: ''}, () => {
            this.props.setMessgageStateTabs();
        })

        await fetch(`/api/tsr/convert_to_qa?lead_id=${this.props.selectedLeadId}`)
        .then(response => {
            if(response.status == 200) {
                return response.json()
            } else {
                return "NA"
            }
        })
        .then(data => {
            if(data == "NA") {
                this.setState({isLoading: true, showMessage: true, message: 'There was some error while converting the lead to QA'})
            } else {
                if(data.status == 'True') {
                    this.setState({isLoading: true, showMessage: true, message: data.msg})
                } else {
                    this.setState({isLoading: true, showMessage: true, message: data.msg})
                }
            }
        })
        .catch(error => {
            console.log(error)
            this.setState({isLoading: true, showMessage: true, message: 'There was some error while converting the lead to QA'})
        })
    }

    saveGeneralData = async () => {
        this.setState({generalIsLoading: true, generalIsLoadingMessage: 'Please wait while we save the data...', generalShowMessage: false, generalMessage: ''})

        let dateChanged = true;
        let dateChangedCallback = true;
        let existingGeneralData = this.props.GeneralStore.generalData;
        for(let i = 0; i < existingGeneralData.length; i++) {
            for (let key of Object.keys(existingGeneralData[i])) {
                if(key == 'appo_date') {
                    if(existingGeneralData[i][key] == this.props.GeneralStore[key]) {
                        dateChanged = false;
                    }
                } else if (key == 'callback_date') {
                    if(existingGeneralData[i][key] == this.props.GeneralStore[key]) {
                        dateChangedCallback = false;
                    }
                }
            }
        }
        
        let dataToSend = {}
        for (let key of Object.keys(GeneralDetailsToSaveMapping)) {
            if(key != 'type') {
                if(key == 'appo_date') {
                    if(this.props.GeneralStore[key]) {
                        if(dateChanged) {
                            if(this.state.appointmentDateEditable) {
                                dataToSend[key] = new Date(this.props.GeneralStore[key]);
                            } else {
                                dataToSend[key] = this.props.GeneralStore[key];
                            }
                        }
                    }
                } else if (key == 'callback_date') {
                    if(this.props.GeneralStore[key]) {
                        if(dateChangedCallback) {
                            dataToSend[key] = new Date(this.props.GeneralStore[key]);
                        }
                    }
                } else {
                    dataToSend[key] = this.props.GeneralStore[key];
                }
            }
        }
        dataToSend['id'] = this.props.selectedLeadId;

        let existingPersonalData = this.props.GeneralStore.personalData;
        for(let i = 0; i < existingPersonalData.length; i++) {
            for (let key of Object.keys(existingPersonalData[i])) {
                if(key != 'type') {
                    dataToSend[key] = this.props.GeneralStore[key];
                }
            }
        }

        let existingPersonalDataCompanyExperienceData = this.props.GeneralStore.personalDataCompanyExperienceData;
        let keyType = 'personal_partnerCompanyExperience_';
        for(let i = 0; i < existingPersonalDataCompanyExperienceData.length; i++) {
            for (let key of Object.keys(existingPersonalDataCompanyExperienceData[i])) {
                if(key != 'type') {
                    dataToSend[key] = this.props.GeneralStore[`${keyType}${key}`];
                }
            }
        }

        let existingPersonalDataCustomerFamilyData = this.props.GeneralStore.personalDataCustomerFamilyData;
        keyType = 'personal_partnerCustomerFamily_';
        for(let i = 0; i < existingPersonalDataCustomerFamilyData.length; i++) {
            for (let key of Object.keys(existingPersonalDataCustomerFamilyData[i])) {
                if(key != 'type') {
                    dataToSend[key] = this.props.GeneralStore[`${keyType}${key}`];
                }
            }
        }

        let existingPersonalDataCustomerEducationData = this.props.GeneralStore.personalDataCustomerEducationData;
        keyType = 'personal_partnerCustomerEducation_';
        for(let i = 0; i < existingPersonalDataCustomerEducationData.length; i++) {
            for (let key of Object.keys(existingPersonalDataCustomerEducationData[i])) {
                if(key != 'type') {
                    dataToSend[key] = this.props.GeneralStore[`${keyType}${key}`];
                }
            }
        }

        let existingLoanInfoData = this.props.GeneralStore.loanInfoData;
        keyType = 'loanInfoDetails_';
        for(let i = 0; i < existingLoanInfoData.length; i++) {
            for (let key of Object.keys(existingLoanInfoData[i])) {
                if(key != 'type') {
                    dataToSend[key] = this.props.GeneralStore[`${keyType}${key}`];
                }
            }
        }

        let existingBankDetailsData = this.props.GeneralStore.bankDetailsData;
        keyType = 'loanInfo_bankDetails_';
        for(let i = 0; i < existingBankDetailsData.length; i++) {
            for (let key of Object.keys(existingBankDetailsData[i])) {
                if(key != 'type') {
                    dataToSend[key] = this.props.GeneralStore[`${keyType}${key}`];
                }
            }
        }

        let existingVehicleRegistrationData = this.props.GeneralStore.vehicleRegistrationData;
        keyType = 'loanInfo_vehicleRegistrationDetails_';
        for(let i = 0; i < existingVehicleRegistrationData.length; i++) {
            for (let key of Object.keys(existingVehicleRegistrationData[i])) {
                if(key != 'type') {
                    dataToSend[key] = this.props.GeneralStore[`${keyType}${key}`];
                }
            }
        }

        let existingBillDetailsData = this.props.GeneralStore.billDetailsData;
        keyType = 'loanInfo_billDetails_';
        for(let i = 0; i < existingBillDetailsData.length; i++) {
            for (let key of Object.keys(existingBillDetailsData[i])) {
                if(key != 'type') {
                    dataToSend[key] = this.props.GeneralStore[`${keyType}${key}`];
                }
            }
        }

        console.log('KAILASH', dataToSend)
        // return;

        await fetch('/api/tsr/lead_details/update', {
            method: 'POST',
            body: JSON.stringify(dataToSend),
            headers: {
                'Content-Type': 'application/json; ',
                Authorization: `Bearer ${this.state.token}`,
            },
        })
        .then(response => {
            if(response.status == 200) {
                return response.json()
            } else {
                return "NA"
            }
        })
        .then(data => {
            if(data == "NA") {
                this.setState({generalIsLoading: true, generalShowMessage: true, generalMessage: 'There was some error. Please check the data and try again', messageColor: 'red', isSavedData: false,})
            } else {
                if(data.status == 'True') {
                    let generalData = this.props.GeneralStore.generalData;
                    for(let i = 1; i < generalData.length; i++) {
                        for (let key of Object.keys(generalData[i])) {
                            generalData[i][key] = this.props.GeneralStore[key];
                        }
                    }
                    let personalData = this.props.GeneralStore.personalData;
                    for(let i = 0; i < personalData.length; i++) {
                        for (let key of Object.keys(personalData[i])) {
                            personalData[i][key] = this.props.GeneralStore[key];
                        }
                    }
                    let personalDataCompanyExperienceData = this.props.GeneralStore.personalDataCompanyExperienceData;
                    keyType = 'personal_partnerCompanyExperience_';
                    for(let i = 0; i < personalDataCompanyExperienceData.length; i++) {
                        for (let key of Object.keys(personalDataCompanyExperienceData[i])) {
                            personalDataCompanyExperienceData[i][key] = this.props.GeneralStore[`${keyType}${key}`];
                        }
                    }
                    let personalDataCustomerFamilyData = this.props.GeneralStore.personalDataCustomerFamilyData;
                    keyType = 'personal_partnerCustomerFamily_';
                    for(let i = 0; i < personalDataCustomerFamilyData.length; i++) {
                        for (let key of Object.keys(personalDataCustomerFamilyData[i])) {
                            personalDataCustomerFamilyData[i][key] = this.props.GeneralStore[`${keyType}${key}`];
                        }
                    }
                    let personalDataCustomerEducationData = this.props.GeneralStore.personalDataCustomerEducationData;
                    keyType = 'personal_partnerCustomerEducation_';
                    for(let i = 0; i < personalDataCustomerEducationData.length; i++) {
                        for (let key of Object.keys(personalDataCustomerEducationData[i])) {
                            personalDataCustomerEducationData[i][key] = this.props.GeneralStore[`${keyType}${key}`];
                        }
                    }
                    let loanInfoData = this.props.GeneralStore.loanInfoData;
                    keyType = 'loanInfoDetails_';
                    for(let i = 0; i < loanInfoData.length; i++) {
                        for (let key of Object.keys(loanInfoData[i])) {
                            loanInfoData[i][key] = this.props.GeneralStore[`${keyType}${key}`];
                        }
                    }
                    let bankDetailsData = this.props.GeneralStore.bankDetailsData;
                    keyType = 'loanInfo_bankDetails_';
                    for(let i = 0; i < bankDetailsData.length; i++) {
                        for (let key of Object.keys(bankDetailsData[i])) {
                            bankDetailsData[i][key] = this.props.GeneralStore[`${keyType}${key}`];
                        }
                    }
                    let vehicleRegistrationData = this.props.GeneralStore.vehicleRegistrationData;
                    keyType = 'loanInfo_vehicleRegistrationDetails_';
                    for(let i = 0; i < vehicleRegistrationData.length; i++) {
                        for (let key of Object.keys(vehicleRegistrationData[i])) {
                            vehicleRegistrationData[i][key] = this.props.GeneralStore[`${keyType}${key}`];
                        }
                    }
                    let billDetailsData = this.props.GeneralStore.billDetailsData;
                    keyType = 'loanInfo_billDetails_';
                    for(let i = 0; i < billDetailsData.length; i++) {
                        for (let key of Object.keys(billDetailsData[i])) {
                            billDetailsData[i][key] = this.props.GeneralStore[`${keyType}${key}`];
                        }
                    }
                    this.setState({generalIsLoading: true, generalShowMessage: true, generalMessage: data.msg, messageColor: 'green', isSavedData: true,}, () => {
                        this.props.GeneralStore.setGeneralData(generalData)
                        this.props.GeneralStore.setPersonalData(personalData)
                        this.props.GeneralStore.setPersonalCompanyExperienceData(personalDataCompanyExperienceData)
                        this.props.GeneralStore.setPersonalCustomerFamilyData(personalDataCustomerFamilyData)
                        this.props.GeneralStore.setPersonalCustomerEducationData(personalDataCustomerEducationData)
                        this.props.GeneralStore.setLoanInfoData(loanInfoData)
                        this.props.GeneralStore.setBankDetailsData(bankDetailsData)
                        this.props.GeneralStore.setVehicleRegistrationData(vehicleRegistrationData)
                        this.props.GeneralStore.setBillDetailsData(billDetailsData)
                        this.props.GeneralStore.toggleEdit(false);
                        this.props.GeneralStore.setGeneralReload(true)
                        this.props.GeneralStore.setPersonalReload(true);
                        this.props.GeneralStore.setLoanInfoReload(true);
                    })
                } else {
                    this.setState({generalIsLoading: true, generalShowMessage: true, generalMessage: data.msg, messageColor: 'red', isSavedData: false,})
                }
            }
        })
        .catch(error => {
            console.log(error)
            this.setState({generalIsLoading: true, generalShowMessage: true, generalMessage: 'There was some error. Please check the data and try again', messageColor: 'red', isSavedData: false,})
        })
    }

    toggleEdit = (value) => {
        this.props.GeneralStore.toggleEdit(value);
    }

    cancelEdit = () => {
        this.props.GeneralStore.cancelEdit();
        let existingGeneralData = this.props.GeneralStore.generalData;
        let all_sub_disposition = [];
        let all_meeting_district_id = [];
        let all_sub_disposition_data = this.state.all_sub_disposition_data;
        let all_meeting_district_id_data = this.state.all_meeting_district_id_data;
        for(let i = 1; i < existingGeneralData.length; i++) {
            for(let j = 0; j < all_sub_disposition_data.length; j++) {
                if('disposition' in existingGeneralData[i]) {
                    if(existingGeneralData[i]['disposition'] == all_sub_disposition_data[j].disposition) {
                        all_sub_disposition.push(all_sub_disposition_data[j])
                    }
                }
            }
            for(let j = 0; j < all_meeting_district_id_data.length; j++) {
                if ('meeting_city_id' in existingGeneralData[i]) {
                    if(existingGeneralData[i]['meeting_city_id'] == all_meeting_district_id_data[j].city_id) {
                        all_meeting_district_id.push(all_meeting_district_id_data[j])
                    }
                }
            }
        }
        this.setState({all_sub_disposition, all_meeting_district_id});

        this.makeAppoEditable();
    }

    makeAppoEditable = () => {
        if(this.props.GeneralStore.disposition) {
            let all_disposition = this.state.all_disposition;
            for(let i = 0; i < all_disposition.length; i++) {
                if(all_disposition[i].name.includes('Interested')) {
                    if(all_disposition[i].id == this.props.GeneralStore.disposition) {
                        if(this.props.GeneralStore.sub_disposition) {
                            let all_sub_disposition = this.state.all_sub_disposition;
                            for(let j = 0; j < all_sub_disposition.length; j++) {
                                if(all_sub_disposition[j].name.includes('Appointment Scheduled')) {
                                    if(all_sub_disposition[j].id == this.props.GeneralStore.sub_disposition) {
                                        this.setState({appointmentDateEditable: true})
                                        break;
                                    } else {
                                        this.setState({appointmentDateEditable: false})
                                        break;
                                    }
                                }
                            }
                        } else {
                            this.setState({appointmentDateEditable: false})
                            break;
                        }
                    } else {
                        this.setState({appointmentDateEditable: false})
                        break;
                    }
                }
            }
        } else {
            this.setState({appointmentDateEditable: false})
        }
    }

    changeText = async (key, myValue) => {
        console.log("CHANGING VALUES", key, myValue)
        if(key == 'disposition') {
            let all_sub_disposition = [];
            let all_sub_disposition_data = this.state.all_sub_disposition_data;
            for(let i = 0; i < all_sub_disposition_data.length; i++) {
                if(myValue == all_sub_disposition_data[i].disposition) {
                    all_sub_disposition.push(all_sub_disposition_data[i])
                }
            }
            this.setState({all_sub_disposition});
        } else if (key == 'meeting_city_id') {
            let all_meeting_district_id = [];
            let all_meeting_district_id_data = this.state.all_meeting_district_id_data;
            for(let i = 0; i < all_meeting_district_id_data.length; i++) {
                if(myValue == all_meeting_district_id_data[i].city_id) {
                    all_meeting_district_id.push(all_meeting_district_id_data[i])
                }
            }
            this.setState({all_meeting_district_id});
        }

        if((key == 'disposition') || (key == 'sub_disposition')) {
            if(key == 'disposition') {
                let all_disposition = this.state.all_disposition;
                for(let i = 0; i < all_disposition.length; i++) {
                    if((all_disposition[i].name.includes('Interested')) && (all_disposition[i].id == myValue)) {
                        if(this.props.GeneralStore.sub_disposition) {
                            let all_sub_disposition = this.state.all_sub_disposition;
                            for(let j = 0; j < all_sub_disposition.length; j++) {
                                if((all_sub_disposition[i].name.includes('Appointment Scheduled')) && (all_sub_disposition[i].id == this.props.GeneralStore.sub_disposition)) {
                                    console.log('Kailash', 'Appointment Scheduled');
                                    this.setState({appointmentDateEditable: true})
                                    break;
                                }
                            }
                        } else {
                            this.setState({appointmentDateEditable: false})
                        }
                    }
                }
            } else {
                let all_sub_disposition = this.state.all_sub_disposition;
                for(let i = 0; i < all_sub_disposition.length; i++) {
                    if((all_sub_disposition[i].name.includes('Appointment Scheduled')) && (all_sub_disposition[i].id == myValue)) {
                        if(this.props.GeneralStore.disposition) {
                            let all_disposition = this.state.all_disposition;
                            for(let j = 0; j < all_disposition.length; j++) {
                                if((all_disposition[j].name.includes('Interested')) && (all_disposition[j].id == this.props.GeneralStore.disposition)) {
                                    console.log('Kailash', 'INTERESTED');
                                    this.setState({appointmentDateEditable: true})
                                    break;
                                }
                            }
                        } else {
                            this.setState({appointmentDateEditable: false})
                        }
                    }
                }
            }
        }

        this.props.GeneralStore.onChangeText(key, myValue);

        if((key == 'disposition') || (key == 'sub_disposition')) {
            if(this.props.GeneralStore.disposition && this.props.GeneralStore.sub_disposition) {
                await this.highlightFields(this.props.GeneralStore.disposition, this.props.GeneralStore.sub_disposition);
            }
        }
    }

    highlightFields = async (disposition, subDisposition) => {
        let myResponse = null;
        try {
            myResponse = await fetch(`/api/tsr/mandatory_fileds?lead_id=${this.props.selectedLeadId}&disposition=${disposition}&sub_disposition=${subDisposition}`);
		} catch (error) {
            console.log(error)
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			jsonResp = await myResponse.json();
		} else {
			return;
		}

		if(jsonResp) {
            if(jsonResp.status == 'True') {
                let highlightFields = jsonResp.dispo_man_fields;
                const values = Object.values(highlightFields);
                console.log("KAILASH", values);
                this.props.GeneralStore.setHighlightedFields(values);
            }
        }
    }

    getGeneralData = async () => {
        this.setState({isPageLoading: true, showPageLoadingMessage: false, pageLoadingMessage: '',})

        let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/lead_details?lead_id=${this.props.selectedLeadId}&lang=${this.context.language}`);
		} catch (error) {
            console.log(error)
            this.setState({isPageLoading: true, showPageLoadingMessage: true, pageLoadingMessage: 'Some error occured while fetching the general details.',})
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			jsonResp = await myResponse.json();
		} else {
            this.setState({isPageLoading: true, showPageLoadingMessage: true, pageLoadingMessage: 'Some error occured while fetching the general details.',})
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                let myGeneralData = jsonResp.res[0]['General Details'];
                try{
                    if(myGeneralData[0]['call_status']=='Hangup') {
                        this.setState({showHangup:true});
                        delete myGeneralData[0]['call_status'];
                    } else {
                        delete myGeneralData[0]['call_status'];
                    }
                } catch(e) {
                    console.log(e);
                }

                try{
                    if(myGeneralData[0]['stage_id'] == 'TSR') {
                        this.setState({showConvertToQA: true});
                        delete myGeneralData[0]['stage_id'];
                    } else {
                        delete myGeneralData[0]['stage_id'];
                    }
                } catch(e) {
                    console.log(e);
                }
                
                let descriptionData = '';
                let i = 0;
                for(i = 0; i < myGeneralData.length; i++) {
                    for (let key of Object.keys(myGeneralData[i])) {
                        if((key == 'appo_date') || (key == 'callback_date')) {
                            if(myGeneralData[i][key] == '') {
                                this.props.GeneralStore.onChangeText(key, null);
                            } else {
                                let d2 = new Date(myGeneralData[i][key]);
                                let localTime2 = d2.getTime();
                                let localOffset2 = d2.getTimezoneOffset() * 60000;
                                let utc2 = localTime2 - localOffset2;
                                let vietnamDate2 = new Date(utc2);
                                myGeneralData[i][key] = vietnamDate2;
                                this.props.GeneralStore.onChangeText(key, vietnamDate2);
                            }
                        } else {
                            this.props.GeneralStore.onChangeText(key, myGeneralData[i][key]);
                        }

                        if(key == 'disposition') {
                            let all_sub_disposition = [];
                            let all_sub_disposition_data = this.state.all_sub_disposition_data;
                            for(let j = 0; j < all_sub_disposition_data.length; j++) {
                                if(myGeneralData[i][key] == all_sub_disposition_data[j].disposition) {
                                    all_sub_disposition.push(all_sub_disposition_data[j])
                                }
                            }
                            this.setState({all_sub_disposition});
                        } else if (key == 'meeting_city_id') {
                            let all_meeting_district_id = [];
                            let all_meeting_district_id_data = this.state.all_meeting_district_id_data;
                            for(let j = 0; j < all_meeting_district_id_data.length; j++) {
                                if(myGeneralData[i][key] == all_meeting_district_id_data[j].city_id) {
                                    all_meeting_district_id.push(all_meeting_district_id_data[j])
                                }
                            }
                            this.setState({all_meeting_district_id});
                        }
                    }
                }

                if((this.state.interestedId == myGeneralData[1].disposition) && (this.state.appointmentId == myGeneralData[1].sub_disposition)) {
                    this.setState({appointmentDateEditable: true})
                } else {
                    this.setState({appointmentDateEditable: false})
                }

                await this.highlightFields(myGeneralData[1].disposition, myGeneralData[1].sub_disposition);

                this.setState({isPageLoading: false, showPageLoadingMessage: false, pageLoadingMessage: '',}, () => {
                    this.props.GeneralStore.setGeneralData(myGeneralData)
                    this.props.GeneralStore.setGeneralReload(false)
                })
			} else {
                this.setState({isPageLoading: true, showPageLoadingMessage: true, pageLoadingMessage: 'Some error occured while fetching the general details.',})
            }
		} else {
            this.setState({isPageLoading: true, showPageLoadingMessage: true, pageLoadingMessage: 'Some error occured while fetching the general details.',})
        }
    }

    getMeetingDistrictId = async (token) => {
        let myResponse = null;
		try {
            myResponse = await fetch(`/api/tsr/district_list/?token=${token}`);
		} catch (error) {
			console.log(error)
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			this.setState({isLoading: false})
			jsonResp = await myResponse.json();
		} else {
			console.log(myResponse)
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                let all_meeting_district_id_data = jsonResp.res;
                this.setState({all_meeting_district_id_data})
			} else {
                console.log(jsonResp)
            }
		} else {
            console.log(jsonResp)
        }
    }

    getCityList = async (token) => {
        let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/city_list/?token=${token}`);
		} catch (error) {
			console.log(error)
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			this.setState({isLoading: false})
			jsonResp = await myResponse.json();
		} else {
			console.log(myResponse)
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                let all_meeting_city_id = jsonResp.res;
                this.setState({all_meeting_city_id})
			} else {
                console.log(jsonResp)
            }
		} else {
            console.log(jsonResp)
        }
    }

    getProductList = async (token) => {
        let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/product_list/?token=${token}`);
		} catch (error) {
			console.log(error)
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			jsonResp = await myResponse.json();
		} else {
			console.log(myResponse)
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                let all_product_type = jsonResp.res;
                this.setState({all_product_type})
			} else {
                console.log(jsonResp)
            }
		} else {
            console.log(jsonResp)
        }
    }

    getSubDispositionList = async (token) => {
        let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/subdisposition_list/?token=${token}`);
		} catch (error) {
			console.log(error)
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			jsonResp = await myResponse.json();
		} else {
			console.log(myResponse)
			return;
		}

		if(jsonResp) {
            console.log(jsonResp)
			if(jsonResp.status == 'True') {
                let all_sub_disposition_data = jsonResp.res;
                let all_sub_disposition_toShow = jsonResp.sub_dispo_list;
                for(let i = 0; i < all_sub_disposition_data.length; i++) {
                    if(all_sub_disposition_data[i].name == 'Appointment Scheduled') {
                        this.setState({appointmentId: all_sub_disposition_data[i].id});
                        break;
                    }
                }
                this.setState({all_sub_disposition_data, all_sub_disposition_toShow})
			} else {
                console.log(jsonResp)
            }
		} else {
            console.log(jsonResp)
        }
    }

    getDispositionList = async (token) => {
        let myResponse = null;
		try {
			myResponse = await fetch(`/api/tsr/disposition_list/?token=${token}`);
		} catch (error) {
			console.log(error)
			return;
        }
        
        let jsonResp = null;
		if(myResponse.status == 200) {
			jsonResp = await myResponse.json();
		} else {
			console.log(myResponse)
			return;
		}

		if(jsonResp) {
			if(jsonResp.status == 'True') {
                let all_disposition = jsonResp.res;
                let all_disposition_toShow = jsonResp.dispo_list;
                for(let i = 0; i < all_disposition.length; i++) {
                    if(all_disposition[i].name == 'Interested') {
                        this.setState({interestedId: all_disposition[i].id});
                        break;
                    }
                }
                this.setState({all_disposition, all_disposition_toShow})
			} else {
                console.log(jsonResp)
            }
		} else {
            console.log(jsonResp)
        }
    }

    getToken = async () => {
        let token = await localStorage.getItem('AccessToken');
        if(token && token != 'undefined') {
            console.log(token)
            return token;
        } else {
            localStorage.removeItem('AccessToken');
            this.props.history.push('login');
        }
    }

    componentWillReceiveProps(props) {
        const { selectedLeadId, clearMessageState } = this.props;
        if(props.clearMessageState !== clearMessageState) {
            this.setState({isLoading: false, showMessage: false, message: ''})
        }
        if(props.selectedLeadId !== selectedLeadId) {
            this.setState({selectedLeadId})
        }
    }

    async componentDidMount() {
        let token = await this.getToken();
        this.setState({token}, () => {
            this.props.setMessgageStateTabs();
        })
        await this.getDispositionList(token);
        await this.getSubDispositionList(token);
        await this.getProductList(token);
        await this.getCityList(token);
        await this.getMeetingDistrictId(token);
        if(this.props.GeneralStore.toReloadGeneral) {
            // this.props.GeneralStore.setGeneralData([])
            await this.getGeneralData();
        } else {
            this.setState({isPageLoading: false})
        }
    }

    getAutocompleteValue = (key) => {
        let allItems = this.state[`all_${key}`];
        let selectedValue = {};
        for(let i = 0; i < allItems.length; i++) {
            if(allItems[i].id == this.props.GeneralStore[key]) {
                selectedValue = allItems[i];
            }
        }
        
        return selectedValue;
    }

    showDropdownValue = (key, value) => {
        if(key == 'no_of_visits') {
            return value;
        } else if (key == 'channel') {
            let myValues = availableValues[key];
            let returnValue = null;
            for(let i = 0; i < myValues.length; i++) {
                if(myValues[i].value == value) { 
                    returnValue = myValues[i].name;
                }
            }
            return returnValue;
        } else if (key == 'existing_credit_card') {
            let myValues = availableValues[key];
            let returnValue = null;
            for(let i = 0; i < myValues.length; i++) {
                if(myValues[i].value == value.toString()) { 
                    returnValue = myValues[i].name;
                }
            }
            return returnValue;
        }

        let myMap = this.state[`all_${key}`];
        let returnValue = null;
        for(let i = 0; i < myMap.length; i++) {
            if(myMap[i].id == value) { 
                returnValue = myMap[i].name;
            } else if (myMap[i].name == value) {
                returnValue = myMap[i].name;
            }
        }

        if(returnValue == null) {
            if(key == 'disposition') {
                let dispList = this.state.all_disposition_toShow;
                for(let i = 0; i < dispList.length; i++) {
                    if(dispList[i].id == value) { 
                        returnValue = dispList[i].name;
                    }
                }
            } else if (key == 'sub_disposition') {
                let subDispList = this.state.all_sub_disposition_toShow;
                for(let i = 0; i < subDispList.length; i++) {
                    if(subDispList[i].id == value) { 
                        returnValue = subDispList[i].name;
                    }
                }
            }
        }

        return returnValue;
    }

    showTextFieldValue = (key, value) => {
        if((key == 'appo_date') || (key == 'callback_date')) {
            if(value) {
                return value.toString();
            } else {
                return null;
            }
        } else {
            return value;
        }
    }

    getDropdowns = (key) => {
        let dataToShow = [];
        if(key == 'no_of_visits') {
            dataToShow.push(
                <FormControl style={{minWidth: 250}}>
                    <Select
                        labelId={`generalDetails_${key}-select-label`}
                        id={`generalDetails_${key}-select-label`}
                        value={this.props.GeneralStore[key]}
                        onChange={event => {this.changeText(key, event.target.value)}}
                    >
                        {this.state.rowsVisits.map((i) => 
                            <MenuItem key={`${key}_${i}`} value={this.state.rowsVisits[i]}>
                                {this.state.rowsVisits[i]}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            );
        } else if ((key == 'channel') || (key == 'existing_credit_card')) {
            dataToShow.push(
                <FormControl style={{minWidth: 250}}>
                    <Select
                        labelId={`generalDetails_${key}-select-label`}
                        id={`generalDetails_${key}-select-label`}
                        value={this.props.GeneralStore[key]}
                        onChange={event => {this.changeText(key, event.target.value)}}
                    >
                        {availableValues[key].map((item) => (
                            <MenuItem key={item.key} value={item.value}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        } else {
            dataToShow.push(
                <FormControl style={{minWidth: 250}}>
                    <Autocomplete
                        labelId={`generalDetails_${key}-select-label`}
                        id={`generalDetails_${key}-select-label`}
                        options={this.state[`all_${key}`]}
                        getOptionLabel={(option) => option.name || ''}
                        value={this.getAutocompleteValue(key)}
                        onChange={(event, value) => {this.changeText(key, (value ? value.id : null))}}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        autoComplete={false}
                    />
                </FormControl>
            )
        }

        return dataToShow;
    }

    getTextFields = (key) => {
        let dataToShow = [];
        
        if (key == 'appo_date') {
            dataToShow.push(
                <FormControl style={{minWidth: 250}}>
                    <DateTimePicker
                        disabled={!this.state.appointmentDateEditable}
                        value={this.props.GeneralStore[key]}
                        onChange={(event) => this.changeText(key, event)}
                        ampm={false}
                    />
                </FormControl>
            )
        } else if (key == 'callback_date') {
            dataToShow.push(
                <FormControl style={{minWidth: 250}}>
                    <DateTimePicker
                        value={this.props.GeneralStore[key]}
                        onChange={(event) => this.changeText(key, event)}
                        ampm={false}
                    />
                </FormControl>
            )
        } else {
            dataToShow.push(
                <FormControl style={{minWidth: 250}}>
                    <TextField id={`outlined-basic-${key}`} label={this.context.language == 'en_US' ? GeneralDetailsMapping[key] : GeneralDetailsMapping_Vt[key]} variant="outlined" onChange={event => this.changeText(key, event.target.value)} value={this.props.GeneralStore[key]} multiline={key == 'description' ? true : false} rows={key == 'description' ? 4 : 1} />
                </FormControl>
            )
        }

        return dataToShow;
    }

	render() {
		const { classes } = this.props;

		return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div style={{display: 'flex', flexDirection: 'column', paddingLeft: 20, paddingRight: 20, borderWidth: 0, borderBottomWidth: 2, borderColor: '#f1f1f1', borderStyle: 'solid',}}>
                {this.state.isPageLoading ? (
                    this.state.showPageLoadingMessage ? (
                        <div style={{flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', height: '80%', justifyContent: 'center', margin: '20%'}}>
                            <div style={{marginTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'red'}}>
                                <span>{this.state.pageLoadingMessage}</span>
                            </div>
                        </div>
                    ) : (
                        <div style={{flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', height: '80%', justifyContent: 'center', margin: '20%'}}>
                            <CircularProgress color='#256cd6' size='50px' style={{color: '#256cd6'}} />
                        </div>
                    )
                ) : (
                    <React.Fragment>
                        {this.state.generalIsLoading && (
                            this.state.generalShowMessage ? (
                                <div style={{flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', height: '80%', justifyContent: 'center', marginRight: '20%', marginTop: 20}}>
                                    <div style={{marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', color: this.state.messageColor ? this.state.messageColor : '#256cd6'}}>
                                        <span style={{fontSize: '20px'}}>{this.state.generalMessage}</span>
                                    </div>
                                </div>
                            ) : (
                                <div style={{flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', height: '80%', justifyContent: 'center', marginRight: '20%', marginTop: 20}}>
                                    <CircularProgress color='#256cd6' size='50px' style={{color: '#256cd6'}} />
                                    <Typography variant="body2" style={{color: '#256cd6'}}>
                                        {this.state.generalIsLoadingMessage}
                                    </Typography>
                                </div>
                            )
                        )}
                        <FormGroup row style={{margin: 20}}>
                            {!!this.state.showConvertToQA && (
                                this.props.GeneralStore.isEditable ? (
                                    <React.Fragment>
                                        <Button variant="contained" color="primary" style={{marginRight: 10}} onClick={this.saveGeneralData}>
                                            Save
                                        </Button>
                                        <Button variant="contained" color="secondary" style={{marginLeft: 10}} onClick={this.cancelEdit}>
                                            Cancel
                                        </Button>
                                    </React.Fragment>
                                ) : (
                                    <Button variant="contained" color="primary" style={{marginRight: 10}} onClick={() => this.toggleEdit(true)}>
                                        Edit
                                    </Button>
                                )
                            )}
                            <div style={{fontSize: 20, fontWeight: 'bold', alignSelf: 'center', textAlign: 'center', justifySelf: 'center', marginLeft: 20}}>
                                {this.props.GeneralStore['name']} - {this.props.GeneralStore['id']}
                            </div>
                        </FormGroup>
                        {/* <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', fontSize: 20, fontWeight: 'bold', marginBottom: 20, marginTop: 10,}}>
                            {this.props.GeneralStore['name']} - {this.props.GeneralStore['id']}
                        </div> */}
                        <div style={{display: 'flex', flexDirection: 'row', paddingRight: 20}}>
                            {this.props.GeneralStore.generalData.map((data, index) => (
                                <React.Fragment>
                                    <div style={{display: 'flex', flexDirection: 'column', margin: 20}}>
                                        {data.type == 'readonly' ? (
                                            Object.entries(data).map(([key,value],i) => (
                                                key != 'type' && (
                                                    <React.Fragment>
                                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 10}} key={`general_${i}`}>
                                                            <Typography variant="caption" className={classes.title} style={{paddingRight: 20, alignSelf: 'center', fontWeight: 'bold'}}>
                                                                {this.context.language == 'en_US' ? GeneralDetailsMapping[key] : GeneralDetailsMapping_Vt[key]}
                                                            </Typography>
                                                            <Typography variant="body2" className={classes.title} style={{paddingLeft: 20}}>
                                                                {value}
                                                            </Typography>
                                                        </div>
                                                        <Divider />
                                                    </React.Fragment>
                                                )
                                            ))
                                        ) : (
                                            Object.entries(data).map(([key,value],i) => (
                                                key != 'type' && (
                                                    <React.Fragment>
                                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 10}} key={`general_${i}`}>
                                                            <Typography variant="caption" className={classes.title} style={{paddingRight: 20, alignSelf: 'center', fontWeight: 'bold'}}>
                                                                    {this.context.language == 'en_US' ? GeneralDetailsMapping[key] : GeneralDetailsMapping_Vt[key]} {!!this.props.GeneralStore.isEditable && (
                                                                    Object.entries(this.props.GeneralStore.highlightedFields).map(([key2,value2],i) => {
                                                                        if(key === value2) {
                                                                            return (<StarRateIcon style={{color: 'red', height: 10, width: 10,}} />);
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })
                                                                )}
                                                            </Typography>
                                                            {this.props.GeneralStore.isEditable ? (
                                                                GeneralDetailsDropdowns.includes(key) ? (
                                                                    this.getDropdowns(key)
                                                                ) : (
                                                                    this.getTextFields(key)
                                                                )
                                                            ) : (
                                                                <Typography variant="body2" className={classes.title} style={{paddingLeft: 20}}>
                                                                    {GeneralDetailsDropdowns.includes(key) ? (
                                                                        this.showDropdownValue(key, value)
                                                                    ) : (
                                                                        this.showTextFieldValue(key, value)
                                                                    )}
                                                                </Typography>
                                                            )}
                                                        </div>
                                                        <Divider />
                                                    </React.Fragment>
                                                )
                                            ))
                                        )}
                                    </div>
                                    {((this.props.GeneralStore.generalData.length - 1) != index) &&
                                        <Divider orientation="vertical" flexItem />
                                    }
                                </React.Fragment>
                            ))}
                        </div>
                        {this.state.isLoading && (
                            this.state.showMessage ? (
                                <div style={{flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', height: '80%', justifyContent: 'center', marginRight: '20%'}}>
                                    <div style={{marginTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', color: this.state.callMessageColor ? this.state.callMessageColor : '#256cd6'}}>
                                        <span>{this.state.message}</span>
                                    </div>
                                </div>
                            ) : (
                                <div style={{flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', height: '80%', justifyContent: 'center', marginRight: '20%'}}>
                                    <CircularProgress color='#256cd6' size='30px' style={{color: '#256cd6'}} />
                                </div>
                            )
                        )}
                        <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10, paddingLeft: 20, paddingRight: 20}}>
                            {!!this.state.showConvertToQA && (
                                <div style={{padding: 20}}>
                                    { this.state.showHangup ? (
                                        <IconButton onClick={this.endCall} title="Hangup">
                                            <CallEndIcon style={{color: 'red', height: 40, width: 40, padding: 10}} />
                                        </IconButton>
                                    ) : (
                                        <IconButton onClick={this.startCalling} title="Call">
                                            <CallIcon style={{color: 'green', height: 40, width: 40, padding: 10}} />
                                        </IconButton>
                                    )}
                                </div>
                            )}
                            {!!this.state.showConvertToQA && (
                                !this.state.showHangup && (
                                    <div style={{padding: 20, alignItems: 'center', display: 'flex', justifyContent: 'center', marginRight: '20%'}}>
                                        <Button variant="contained" color="primary" style={{marginRight: 10}} onClick={this.convertToQA}>
                                            Convert to QA
                                        </Button>
                                    </div>
                                )
                            )}
                            {/* <div>
                                <h5>Hello</h5>
                            </div> */}
                        </div>
                    </React.Fragment>
                )}
            </div>
            </MuiPickersUtilsProvider>
        )
    }
}

export default withRouter(withStyles(styles)(ManinNavHeader));
