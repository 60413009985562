import React, { Component } from "react";
import {
	EditingState,
} from "@devexpress/dx-react-grid";
import {
	Grid,
	Table,
	TableHeaderRow,
	TableEditRow,
	TableEditColumn,
	TableFixedColumns,
} from "@devexpress/dx-react-grid-material-ui";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TableCell from "@material-ui/core/TableCell";
import TextField from '@material-ui/core/TextField';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import { withStyles } from "@material-ui/core/styles";

import { availableValues, DateValues, DateKeyMapping } from './Common';

const styles = (theme) => ({
	lookupEditCell: {
		padding: theme.spacing(1),
	},
	dialog: {
		width: "calc(100% - 16px)",
	},
	inputRoot: {
		width: "100%",
	},
	selectMenu: {
		position: "absolute !important",
	},
});

const AddButton = ({ onExecute }) => (
	<div style={{ textAlign: "center" }}>
		<Button color="primary" onClick={onExecute} title="Create new row">
			New
		</Button>
	</div>
);

const EditButton = ({ onExecute }) => (
	<IconButton onClick={onExecute} title="Edit row">
		<EditIcon />
	</IconButton>
);

const DeleteButton = ({ onExecute }) => (
	<IconButton
		onClick={() => {
			// eslint-disable-next-line
			if (window.confirm("Are you sure you want to delete this row?")) {
				onExecute();
			}
		}}
		title="Delete row"
	>
		<DeleteIcon />
	</IconButton>
);

const CommitButton = ({ onExecute }) => (
	<IconButton onClick={onExecute} title="Save changes">
		<SaveIcon />
	</IconButton>
);

const CancelButton = ({ onExecute }) => (
	<IconButton color="secondary" onClick={onExecute} title="Cancel changes">
		<CancelIcon />
	</IconButton>
);

const commandComponents = {
	add: AddButton,
	edit: EditButton,
	delete: DeleteButton,
	commit: CommitButton,
	cancel: CancelButton,
};

const Command = ({ id, onExecute }) => {
	const CommandButton = commandComponents[id];
	return <CommandButton onExecute={onExecute} />;
};

const LookupEditCellBase = ({
	availableColumnValues,
	value,
	onValueChange,
	classes,
}) => {
	
    return (
        <TableCell className={classes.lookupEditCell}>
            <Select
                value={value}
                onChange={(event) => {console.log(onValueChange); return onValueChange(event.target.value)}}
                MenuProps={{
                    className: classes.selectMenu,
                }}
                input={<Input classes={{ root: classes.inputRoot }} />}
            >
                {availableColumnValues.map((item) => (
                    <MenuItem key={item.key} value={item.name}>
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        </TableCell>
    );
}

const DateEditCellBase = ({
	dateKey,
	value,
	onValueChange,
	classes,
	label,
}) => {
	return (
		<TableCell className={classes.lookupEditCell}>
			<TextField
				id={`outlined-basic-${dateKey}`}
				// label={label}
				type="date"
				InputLabelProps={{
					shrink: true,
				}}
				value={value}
				onChange={(event) => onValueChange(event.target.value)}
			/>
		</TableCell>
    );
}

export const LookupEditCell = withStyles(styles, {
	name: "ControlledModeDemo",
})(LookupEditCellBase);

export const DateEditCell = withStyles(styles, {
	name: "ControlledModeDateDemo",
})(DateEditCellBase);

const Cell = (props) => {
	const { column } = props;
	return <Table.Cell {...props} />;
};

const EditCell = (props) => {
	const { column } = props;
	console.log(column.name)
	const availableColumnValues = availableValues[column.name];
    if (availableColumnValues) {
		return (
			<LookupEditCell
				{...props}
				availableColumnValues={availableColumnValues}
			/>
		);
	} else if (DateValues.includes(column.name)) {
		return (
			<DateEditCell
				{...props}
				dateKey={column.name}
				label={DateKeyMapping[column.name]}
			/>
		);
	}
	return <TableEditRow.Cell {...props} />;
};

const getRowId = (row) => row.id;

class CustomTableNew extends Component {

	constructor(props) {
		super(props);

		this.state = {
            tableColumnExtensions: [],
            leftFixedColumns: [TableEditColumn.COLUMN_TYPE],
		};
    }

    componentDidMount() {
        console.log(this.props.rows)
        console.log(this.props.columns)
    }

    render() {
        const { classes } = this.props;

		return (
            <Paper className={classes.root}>
                <Grid rows={this.props.rows} columns={this.props.columns} getRowId={getRowId}>
                    <EditingState
						editingRowIds={this.props.editingRowIds}
						onEditingRowIdsChange={this.props.getEditingRowIds}
						rowChanges={this.props.rowChanges}
						onRowChangesChange={this.props.setRowChanges}
                        addedRows={this.props.addedRows}
                        onAddedRowsChange={this.props.changeAddedRows}
                        onCommitChanges={this.props.commitChanges}
                    />
                    <Table
                        columnExtensions={this.state.tableColumnExtensions}
                        cellComponent={Cell}
                    />
                    <TableHeaderRow />
                    <TableEditRow cellComponent={EditCell} />
                    <TableEditColumn
                        width={170}
						showAddCommand={!this.props.addedRows.length}
						// showEditCommand
                        commandComponent={Command}
                    />
                    <TableFixedColumns leftColumns={this.state.leftFixedColumns} />
                </Grid>
            </Paper>
        )
    }
};

export default withStyles(styles)(CustomTableNew);