import React, { Component , useEffect, useContext} from "react";
import './App.css';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";

import HomeScreen from "./components/HomeScreen";
import OdooContextProvider from "./contexts/OdooContext";
import {OdooContext} from './contexts/OdooContext'
import Navbar from './components/Navbar';
import SignIn from "./components/login/Signin";
import Dialer from './components/dialer'
import PropTypes from 'prop-types'
import { sipPropType, callPropType,SipProvider } from '@evercall/react-sip';

function App() {
	const [isUserAuthenticated,setUserAuthenticated]=React.useState(false)
	const [pageLoad,setPageLoad]=React.useState(true)
	// const {incomingLeadId} = useContext(OdooContext)

	useEffect(()=>{
		let accessToken = localStorage.getItem("AccessToken");
		if (accessToken){
			setUserAuthenticated(true)
			setPageLoad(false)
		}else{
			setUserAuthenticated(false)
			setUserAuthenticated(false)
		}
	},[])

	// useEffect(()=>{
	// 		console.log("=================Incoming Lead ID=============>",incomingLeadId)
	// },[incomingLeadId])

	return(
		<div className="App">
					{/* <OdooContextProvider> */}
					<Router>
						<Navbar>
							<Switch>
								<Route
									exact
									path="/"
									render={() => {
										return isUserAuthenticated ? (
											<Redirect to="/home" />
										) : (
											<Redirect to="/login" />
										);
									}}
								/>
								<Route
									path="/login"
									exact={true}
									activeClassName="is-active"
									render={() => {
										return isUserAuthenticated ? (
											<Redirect to="/home" />
										) : null;
									}}
									component={()=><SignIn setUserAuthenticated={setUserAuthenticated} />}
								/>
								<Route
									path="/home"
									exact={true}
									activeClassName="is-active"
									render={() => {
										return (!(isUserAuthenticated) && (
											<Redirect to="/login" />
										));
									}}
									component={()=><HomeScreen setUserAuthenticated={setUserAuthenticated}  />}
								/>
								<Route
									path="*"
									exact
									render={() => {
										return isUserAuthenticated ? (
											<Redirect to="/home" />
										) : (
											<Redirect to="/login" />
										);
									}}
								/>
							</Switch>
						</Navbar>
					</Router>
				{isUserAuthenticated && 
				      <SipProvider
					  host='callserver1.thevoicemate.com'
					  port={8089}
					  user='9001'
					  pathname="/ws"
					  password='f61818731087112de9045203b2c7bbe7'
					  autoRegister={true} // true by default, see jssip.UA option register
					  autoAnswer={false} // automatically answer incoming calls; false by default
					  iceRestart={false} // force ICE session to restart on every WebRTC call; false by default
					  sessionTimersExpires={120} // value for Session-Expires header; 120 by default
					  debug={true} // whether to output events to console; false by default
					  inboundAudioDeviceId={"default"} // default, or a deviceId obtained from navigator.mediaDevices.enumerateDevices() where kind is "audioinput"
					  outboundAudioDeviceId={"default"} // default, or a deviceId obtained from navigator.mediaDevices.enumerateDevices() where kind is "audiooutput" 
					  >
				<Dialer />
				</SipProvider>
				
				}
			 {/* </OdooContextProvider> */}
				</div>
	)

}



export default App;
