import React, { createContext, Component } from 'react';

export const OdooContext = createContext();

class OdooContextProvider extends Component {
    state = {
        username: '',
        loggedIn: false,
        language: 'en_US',
        disablePrev: false,
        disableNext: false,
        showDialer: false,
        startCall: false,
        incomingCaller:'',
        incomingLeadId:'',
        leadData:[],
        token:''
    }

    setToken = (token) =>{
        this.setState({token})
    }

    setStartCall = (bool) =>{
        this.setState({startCall:bool})
    }

    setUserName = (username) => {
        this.setState({username: username})
    }

    setLoginStatus = (value) => {
        this.setState({loggedIn: value})
    }

    changeLanguage = (newLanguage) => {
        this.setState({language: newLanguage,})
    }

    changePrevNext = (value) => {
        this.setState({disablePrev: value, disableNext: value});
    }

    setShowDialer=(bool)=>{
        this.setState({showDialer:bool})
    }

    setIncomingCaller = (value)=>{
        this.setState({incomingCaller:value})
    }

    setLeadData=(data)=>{
        this.setState({leadData:data})
    }

    setIncomingLeadId = (id)=>{
        this.setState({incomingLeadId:id})
    }

    componentDidMount() {
        let language = localStorage.getItem('UserLanguage');
        if(language) {
            this.setState({language});
        }
    }

    render() {
        return (
            <OdooContext.Provider 
            value={{...this.state, 
            setUserName: this.setUserName, 
            setShowDialer:this.setShowDialer,
            setIncomingCaller: this.setIncomingCaller,
            setIncomingLeadId: this.setIncomingLeadId,
            hideSideNav: this.hideSideNav, 
            setLoginStatus: this.setLoginStatus, 
            changeLanguage: this.changeLanguage, 
            toRefreshList: this.toRefreshList, 
            setLeadData: this.setLeadData,
            setStartCall: this.setStartCall,
            setToken:this.setToken,
            setToRefreshListPersonal: this.setToRefreshListPersonal, 
            setToRefreshListHistory: this.setToRefreshListHistory, 
            setToRefreshListProduct: this.setToRefreshListProduct, 
            setToRefreshListLoan: this.setToRefreshListLoan, 
            changePrevNext: this.changePrevNext}} >
                {this.props.children}
            </OdooContext.Provider>
        )
    }
}

export default OdooContextProvider;
