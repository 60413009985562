import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {CALL_STARTING,CALL_ACTIVE,CALL_IDLE,CALL_OUTGOING,CALL_INCOMING} from '../../actionTypes'
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import MicOnIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import DialpadIcon from "@material-ui/icons/Dialpad";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import CallIcon from "@material-ui/icons/Call";
import CallEndIcon from "@material-ui/icons/CallEnd";

import { Context } from "../phone";

const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: red
  }
});

const greenCall={
  backgroundColor:'#28B525',
  color:'#fff'
}

const redCall ={
  backgroundColor: '#D12A35',
  color:'#fff'
}


const blueCall ={
  backgroundColor: '#256CD6',
  color:'#fff'
}

const CallKey = ({ isHolding, onClick,Sip }) => (
  <Fab
    aria-label="call phone number"
    style={
      (Sip.call.status === CALL_STARTING && Sip.call.direction === CALL_INCOMING)?greenCall:
      (Sip.call.status === CALL_ACTIVE || Sip.call.status === CALL_STARTING)?redCall:
      blueCall
    }
    onClick={onClick}
    onMouseDown={e => e.stopPropagation()}
  >
    {!(Sip.call.status === CALL_ACTIVE || Sip.call.status === CALL_STARTING) ? <CallIcon /> : <CallEndIcon />}
  </Fab>
);

const Controls = styled(({ className, number, onToggleKeypad, Sip, handleReceiveCall, handleDialCall,handleEndCall }) => {
  const { isInCall, muteCall, startCall,endCall} = useContext(Context);
  const [isMuted, setIsMuted] = useState(false);

  const toggleMute = () => {
    Sip.call.toggleMuteMicrophone()
    // muteCall();
    // setIsMuted(isMuted => !isMuted);
  };

  const isHolding = !isInCall();

  const toggleCall = () => (isHolding ? startCall(number) : endCall());

  // function receiveCall(){

  // }

  return (
    <div className={className}>
      {(
        <div className="__group">
          <Button onClick={toggleMute}>
            {!Sip.call.microphoneIsMuted ? <MicOnIcon /> : <MicOffIcon />}
          </Button>
          <Button>
            <DialpadIcon onClick={onToggleKeypad} />
          </Button>
          <Button disbaled={true} >
            <SwapHorizIcon onClick={() => {}} /> {/* TODO */}
          </Button>
        </div>
      )}
      <div style={{paddingTop:20}} >
       <CallKey isHolding={isHolding} 
       onClick={
         Sip.call.status===CALL_IDLE?handleDialCall:
         (Sip.call.status === CALL_STARTING && Sip.call.direction === CALL_INCOMING)?handleReceiveCall:handleEndCall} 

       Sip={Sip} />
      </div>
    </div>
  );
})`
  display: flex;
  flex-direction: column;
  align-items: center;

  .__group {
    padding-bottom: 1rem;
    display:flex;
    justify-content:center;
    align-items:center;
  }
`;

Controls.dsiplayName = "Controls";

Controls.propTypes = {
  number: PropTypes.string,
  onToggleKeypad: PropTypes.func
};

export default Controls;
